$heading-font:'Oswald', sans-serif;
$common-font: 'Rubik', sans-serif;
$common-font-size: 15px;
$common-font-color:#3c3c3c;
$clr-black:#000;
$clr-dark:#0b2f44;
$clr-bg-light:#f9f9f9;
$clr-white:#fff;
$clr-primary:#ff8a00;
$clr-gray:#f2faff;
$clr-sky-blue:#1e8fd0;
$clr-sky-blue-light:#d6e9fa;
$clr-gray-light:#f2faff;
$clr-white-bg-dark:#acacac;
$transition: .5s all ease;
$outline-none:0px none;
$inherit:inherit;
$none:none;