@import "public/assets/css/variables";

.condition{
    margin: 10px 0;
    font-size: 18px;
    display: flex;
    gap: 3px;
    span{
        color: $clr-primary;
        font-weight: 500;
    }
}