/*===GENERAL===*/
.site-text-primary{color:$clr-primary;}
.site-text-black{color: $clr-black;}
.site-text-gray{color: $clr-gray;} 
.site-text-gray-light{color: $clr-gray-light;}
.site-text-white{color:$clr-white;}
.site-bg-primary{background-color: $clr-primary;}
.site-bg-black{background-color: $clr-black;}
.site-bg-dark{background-color: $clr-dark;}
.site-bg-gray{background-color: $clr-gray;}
.site-bg-gray-light{background-color: $clr-gray-light;}
.site-bg-light{background-color: $clr-bg-light;}
.site-bg-white{background-color: $clr-white;}
.site-bg-sky{background-color: $clr-sky-blue;}
.site-bg-sky-blue-light{background-color:$clr-sky-blue-light}

body {
	color: $common-font-color;
	font-family: $common-font;
	line-height: 1.7;
	font-weight: 400;
	padding: 0px;
	margin: 0px;
	overflow-x: hidden;
	font-size:$common-font-size;
}
a{
	color:$clr-dark;
	outline: $outline-none;
	text-decoration: $none;
    &:hover,&:focus{outline: $outline-none; text-decoration: $none;}
    &:active,&:hover,&:focus {color: $inherit;}
}
.overflow-hide{
	overflow: hidden;
}
img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
/*Float clearfix*/
.clearfix{
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.shadow-bx{
	box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}

.wt-icon-box-wraper {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
ol{
	&.commentlist {
		li {
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
	}
}

section,article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,summary {display: block;}
ul{padding: 0px;}

::selection {
	background: #484848;
	color: #fff;
}

/*===Heading & Title====*/
h1,h2,h3,h4,h5,h6 {
	font-family: $heading-font;
    line-height:1.2;
    color: $clr-dark;
}
h1 {font-size: 60px;font-weight:700;}
h2 {font-size: 45px;font-weight:600;}
h3 {font-size: 22px;font-weight:600;}
h4 {font-size: 18px;font-weight:600;}
h5 {font-size: 16px;font-weight:600;}
h6 {font-size: 14px;font-weight: 400;}

/*---Text align--- */
.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-justify {	text-align: justify}

/*===Listing Style===*/

dl,ul,ol {list-style-position: outside;	padding: 0px;}
ul,ol {	margin-bottom: 24px;}
ul {
	li {
		padding: 0px;
	}
}
ol {
	li {
		padding: 0px;
	}
}

.list-simple{
	li {
		margin-bottom: 10px;
		ul{
			margin-left: 15px;
			margin-top: 10px;
		}
	}
	&.list-unstyled{
		li{
			ul{
				margin-left: 30px;
				margin-top: 10px;
			}
		}
	}
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
	margin: 0px 0px 20px 0px;
	padding: 0px;
	list-style: none;
	font-weight: 600;
	li{padding: 5px 5px 5px 20px;
	  position: relative;

	  &:before{
		font-family: "FontAwesome";
		position: absolute;
		left: 0px;
		top: 3px;
		display: block;
		font-size: 15px;
		color: $clr-black;
	  }
	}
}

.list-circle{
	li{
		&:before {
			top: 6px;
			font-size: 10px;
			content: "\f111";
		}
	}
}
.list-angle-right{
	li{
		&:before {
			content: "\f105";
		}
	}
}
.list-arrow{
	li{
		&:before {
			content: "\f0da";
		}
	}
}
.list-check{
	li{
		&:before {
			content: "\f00c";
		}
	}
}
.list-checked{
	li{
		&:before {
			content: "\f046";
		}
	}
}
.list-check-circle{
	li{
		&:before {
			content: "\f058";
		}
	}
}
.list-chevron-circle{
	li{
		&:before {
			content: "\f138";
		}
	}
}
.list-arrow-circle{
	li{
		&:before {
			content: "\f0a9";
		}
	}
}
.list-times-circle{
	li{
		&:before {
			content: "\f057";
		}
	}
}

/*---Padding (around)---*/

.p-a0 {padding: 0px;}
.p-a5 {padding: 5px;}
.p-a10 {padding: 10px;}
.p-a15 {padding: 15px;}
.p-a20 {padding: 20px;}
.p-a25 {padding: 25px;}
.p-a30 {padding: 30px;}
.p-a40 {padding: 40px;}
.p-a50 {padding: 50px;}
.p-a60 {padding: 60px;}
.p-a70 {padding: 70px;}
.p-a80 {padding: 80px;}
.p-a90 {padding: 90px;}
.p-a100 {padding: 100px;}
.p-a110 {padding: 110px;}
.p-a120 {padding: 120px;}
.p-a130 {padding: 130px;}
.p-a140 {padding: 140px;}
.p-a150 {padding: 150px;}

/*---Padding (top)---*/
.p-t0 {	padding-top: 0px;}
.p-t5 {	padding-top: 5px;}
.p-t10 {padding-top: 10px;}
.p-t15 {padding-top: 15px;}
.p-t20 {padding-top: 20px;}
.p-t30 {padding-top: 30px;}
.p-t40 {padding-top: 40px;}
.p-t50 {padding-top: 50px;}
.p-t60 {padding-top: 60px;}
.p-t70 {padding-top: 70px;}
.p-t80 {padding-top: 80px;}
.p-t90 {padding-top: 90px;}
.p-t100 {padding-top: 100px;}
.p-t110 {padding-top: 110px;}
.p-t120 {padding-top: 120px;}
.p-t130 {padding-top: 130px;}
.p-t140 {padding-top: 140px;}
.p-t150 {padding-top: 150px;}

/*---Padding (bottom)---*/
.p-b0 {	padding-bottom: 0px;}
.p-b5 {	padding-bottom: 5px;}
.p-b10 {padding-bottom: 10px;}
.p-b15 {padding-bottom: 15px;}
.p-b20 {padding-bottom: 20px;}
.p-b30 {padding-bottom: 30px;}
.p-b40 {padding-bottom: 40px;}
.p-b50 {padding-bottom: 50px;}
.p-b60 {padding-bottom: 60px;}
.p-b70 {padding-bottom: 70px;}
.p-b80 {padding-bottom: 80px;}
.p-b90 {padding-bottom: 90px;}
.p-b100 {padding-bottom: 100px;}
.p-b110 {padding-bottom: 110px;}
.p-b120 {padding-bottom: 120px;}
.p-b130 {padding-bottom: 130px;}
.p-b140 {padding-bottom: 140px;}
.p-b150 {padding-bottom: 150px;}

/*---Padding (left)---*/
.p-l0 {	padding-left: 0px;}
.p-l5 {	padding-left: 5px;}
.p-l10 {padding-left: 10px;}
.p-l15 {padding-left: 15px;}
.p-l20 {padding-left: 20px;}
.p-l30 {padding-left: 30px;}
.p-l40 {padding-left: 40px;}
.p-l50 {padding-left: 50px;}
.p-l60 {padding-left: 60px;}
.p-l70 {padding-left: 70px;}
.p-l80 {padding-left: 80px;}
.p-l90 {padding-left: 90px;}
.p-l100 {padding-left: 100px;}
.p-l110 {padding-left: 110px;}
.p-l120 {padding-left: 120px;}
.p-l130 {padding-left: 130px;}
.p-l140 {padding-left: 140px;}
.p-l150 {padding-left: 150px;}

/*---Padding (right)---*/
.p-r0 {	padding-right: 0px;}
.p-r5 {	padding-right: 5px;}
.p-r10 {padding-right: 10px;}
.p-r15 {padding-right: 15px;}
.p-r20 {padding-right: 20px;}
.p-r30 {padding-right: 30px;}
.p-r40 {padding-right: 40px;}
.p-r50 {padding-right: 50px;}
.p-r60 {padding-right: 60px;}
.p-r70 {padding-right: 70px;}
.p-r80 {padding-right: 80px;}
.p-r90 {padding-right: 90px;}
.p-r100 {padding-right: 100px;}
.p-r110 {padding-right: 110px;}
.p-r120 {padding-right: 120px;}
.p-r130 {padding-right: 130px;}
.p-r140 {padding-right: 140px;}
.p-r150 {padding-right: 150px;}

/*---Padding (left right)---*/
.p-lr0 {padding-left: 0px;	padding-right: 0px;}
.p-lr5 {padding-left: 5px;	padding-right: 5px;}
.p-lr10 {padding-left: 10px;	padding-right: 10px;}
.p-lr15 {padding-left: 15px;	padding-right: 15px;}
.p-lr20 {padding-left: 20px;	padding-right: 20px;}
.p-lr30 {padding-left: 30px;	padding-right: 30px;}
.p-lr40 {padding-left: 40px;	padding-right: 40px;}
.p-lr50 {padding-left: 50px;	padding-right: 50px;}
.p-lr60 {padding-left: 60px;	padding-right: 60px;}
.p-lr70 {padding-left: 70px;	padding-right: 70px;}
.p-lr80 {padding-left: 80px;	padding-right: 80px;}
.p-lr90 {padding-left: 90px;	padding-right: 90px;}
.p-lr100 {padding-left: 100px;	padding-right: 100px;}
.p-lr120 {padding-left: 120px;	padding-right: 120px;}
.p-lr150 {padding-left: 150px;	padding-right: 150px;}

/*---Padding (top bottom)---*/
.p-tb0 {padding-bottom: 0px;	padding-top: 0px;}
.p-tb5 {padding-bottom: 5px;	padding-top: 5px;}
.p-tb10 {padding-bottom: 10px;	padding-top: 10px;}
.p-tb15 {padding-bottom: 15px;	padding-top: 15px;}
.p-tb20 {padding-bottom: 20px;	padding-top: 20px;}
.p-tb30 {padding-bottom: 30px;	padding-top: 30px;}
.p-tb40 {padding-bottom: 40px;	padding-top: 40px;}
.p-tb50 {padding-bottom: 50px;	padding-top: 50px;}
.p-tb60 {padding-bottom: 60px;	padding-top: 60px;}
.p-tb70 {padding-bottom: 70px;	padding-top: 70px;}
.p-tb80 {padding-bottom: 80px;	padding-top: 80px;}
.p-tb90 {padding-bottom: 90px;	padding-top: 90px;}
.p-tb100 {padding-bottom: 100px;	padding-top: 100px;}
.p-tb120 {padding-bottom: 120px;	padding-top: 120px;}
.p-tb150 {padding-bottom: 150px;	padding-top: 150px;}

/*----Margin (around)----*/
.m-a-1 {margin: -1px}
.m-a0 {	margin: 0px;}
.m-a5 {	margin: 5px;}
.m-a10 {margin: 10px;}
.m-a15 {margin: 15px;}
.m-a20 {margin: 20px;}
.m-a30 {margin: 30px;}
.m-a40 {margin: 40px;}
.m-a50 {margin: 50px;}
.m-a60 {margin: 60px;}
.m-a70 {margin: 70px;}
.m-a80 {margin: 80px;}
.m-a90 {margin: 90px;}
.m-a100 {margin: 100px;}
.m-a110 {margin: 110px;}
.m-a120 {margin: 120px;}
.m-a130 {margin: 130px;}
.m-a140 {margin: 140px;}
.m-a150 {margin: 150px;}


/*---Marging (top)----*/
.m-t0 {	margin-top: 0px;}
.m-t5 {	margin-top: 5px;}
.m-t10 {margin-top: 10px;}
.m-t15 {margin-top: 15px;}
.m-t20 {margin-top: 20px;}
.m-t30 {margin-top: 30px;}
.m-t40 {margin-top: 40px;}
.m-t50 {margin-top: 50px;}
.m-t60 {margin-top: 60px;}
.m-t70 {margin-top: 70px;}
.m-t80 {margin-top: 80px;}
.m-t90 {margin-top: 90px;}
.m-t100 {margin-top: 100px;}
.m-t110 {margin-top: 110px;}
.m-t120 {margin-top: 120px;}
.m-t130 {margin-top: 130px;}
.m-t140 {margin-top: 140px;}
.m-t150 {margin-top: 150px;}

/*---Marging (bottom)---*/
.m-b0 {	margin-bottom: 0px;}
.m-b5 {	margin-bottom: 5px;}
.m-b10 {margin-bottom: 10px;}
.m-b15 {margin-bottom: 15px;}
.m-b20 {margin-bottom: 20px;}
.m-b30 {margin-bottom: 30px;}
.m-b40 {margin-bottom: 40px;}
.m-b50 {margin-bottom: 50px;}
.m-b60 {margin-bottom: 60px;}
.m-b70 {margin-bottom: 70px;}
.m-b80 {margin-bottom: 80px;}
.m-b90 {margin-bottom: 90px;}
.m-b100 {margin-bottom: 100px;}
.m-b110 {margin-bottom: 110px;}
.m-b120 {margin-bottom: 120px;}
.m-b130 {margin-bottom: 130px;}
.m-b140 {margin-bottom: 140px;}
.m-b150 {margin-bottom: 150px;}

/*---Marging (left)---*/
.m-l0 {	margin-left: 0px;}
.m-l5 {	margin-left: 5px;}
.m-l10 {margin-left: 10px;}
.m-l15 {margin-left: 15px;}
.m-l20 {margin-left: 20px;}
.m-l30 {margin-left: 30px;}
.m-l40 {margin-left: 40px;}
.m-l50 {margin-left: 50px;}
.m-l60 {margin-left: 60px;}
.m-l70 {margin-left: 70px;}
.m-l80 {margin-left: 80px;}
.m-l90 {margin-left: 90px;}
.m-l100 {margin-left: 100px;}
.m-l110 {margin-left: 110px;}
.m-l120 {margin-left: 120px;}
.m-l130 {margin-left: 130px;}
.m-l140 {margin-left: 140px;}
.m-l150 {margin-left: 150px;}

/*---Marging (right)---*/
.m-r0 {	margin-right: 0px;}
.m-r5 {	margin-right: 5px;}
.m-r10 {margin-right: 10px;}
.m-r15 {margin-right: 15px;}
.m-r20 {margin-right: 20px;}
.m-r30 {margin-right: 30px;}
.m-r40 {margin-right: 40px;}
.m-r50 {margin-right: 50px;}
.m-r60 {margin-right: 60px;}
.m-r70 {margin-right: 70px;}
.m-r80 {margin-right: 80px;}
.m-r90 {margin-right: 90px;}
.m-r100 {margin-right: 100px;}
.m-r110 {margin-right: 110px;}
.m-r120 {margin-right: 120px;}
.m-r130 {margin-right: 130px;}
.m-r140 {margin-right: 140px;}
.m-r150 {margin-right: 150px;}

/*---Marging (left right)---*/
.m-lr0 {margin-left: 0px;	margin-right: 0px;}
.m-lr5 {margin-left: 5px;	margin-right: 5px;}
.m-lr10 {margin-left: 10px;	margin-right: 10px;}
.m-lr15 {margin-left: 15px;	margin-right: 15px;}
.m-lr20 {margin-left: 20px;	margin-right: 20px;}
.m-lr30 {margin-left: 30px;	margin-right: 30px;}
.m-lr40 {margin-left: 40px;	margin-right: 40px;}
.m-lr50 {margin-left: 50px;	margin-right: 50px;}
.m-lr60 {margin-left: 60px;	margin-right: 60px;}
.m-lr70 {margin-left: 70px;	margin-right: 70px;}
.m-lr80 {margin-left: 80px;	margin-right: 80px;}
.m-lr90 {margin-left: 90px;	margin-right: 90px;}
.m-lr100 {margin-left: 100px;	margin-right: 100px;}
.m-lr120 {margin-left: 120px;	margin-right: 120px;}
.m-lr150 {margin-left: 150px;	margin-right: 150px;}

/*---Marging (top bottom)---*/
.m-tb0 {margin-bottom: 0px;	margin-top: 0px;}
.m-tb5 {margin-bottom: 5px;	margin-top: 5px;}
.m-tb10 {margin-bottom: 10px;	margin-top: 10px;}
.m-tb15 {margin-bottom: 15px;	margin-top: 15px;}
.m-tb20 {margin-bottom: 20px;	margin-top: 20px;}
.m-tb30 {margin-bottom: 30px;	margin-top: 30px;}
.m-tb40 {margin-bottom: 40px;	margin-top: 40px;}
.m-tb50 {margin-bottom: 50px;	margin-top: 50px;}
.m-tb60 {margin-bottom: 60px;	margin-top: 60px;}
.m-tb70 {margin-bottom: 70px;	margin-top: 70px;}
.m-tb80 {margin-bottom: 80px;	margin-top: 80px;}
.m-tb90 {margin-bottom: 90px;	margin-top: 90px;}
.m-tb100 {margin-bottom: 100px;	margin-top: 100px;}
.m-tb120 {margin-bottom: 120px;	margin-top: 120px;}
.m-tb150 {margin-bottom: 150px;	margin-top: 150px;}

/*---Colum gap less---*/
.no-col-gap{
	[class*="col-xs-"],
	[class*="col-sm-"],
	[class*="col-md-"],
	[class*="col-lg-"] {
		padding-left: 0px;
		padding-right: 0px;
	}
}


/*===Background Effect===*/
.bg-center {background-position: center;}
.bg-top-left {background-position: top left;}
.bg-top-right {background-position: top right;}
.bg-top-center {background-position: top center;}
.bg-bottom-left {background-position: bottom left;}
.bg-bottom-right {background-position: bottom right;}
.bg-bottom-center {background-position: bottom center;}
.bg-left-center {background-position: left;}
.bg-right-center {background-position: left;}
.bg-auto {background-size: auto;}
.bg-contain {background-size: contain;}
.bg-cover {	background-size: cover;}
.bg-full-width {background-size: 100% auto;}
.bg-full-height {background-size: auto 100%;}
.bg-repeat {background-repeat: repeat;}
.bg-no-repeat {background-repeat: no-repeat;}
.bg-repeat-x {background-repeat: repeat-x;}
.bg-repeat-y {background-repeat: repeat-y;}
.bg-fixed {background-attachment: fixed;}
.bg-scroll {background-attachment: scroll;}

/*===Overlay ( transparient box style)===*/

.overlay-wraper {position: relative;}
.overlay-main {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}
.opacity-01 {opacity: 0.1;}
.opacity-02 {opacity: 0.2;}
.opacity-03 {opacity: 0.3;}
.opacity-04 {opacity: 0.4;}
.opacity-05 {opacity: 0.5;}
.opacity-06 {opacity: 0.6;}
.opacity-07 {opacity: 0.7;}
.opacity-08 {opacity: 0.8;}
.opacity-09 {opacity: 0.9;}
.overlay-light{ 
	.overlay-main {
			opacity: 0.3;
	}
}
.overlay-dark{
	.overlay-main{
		opacity: 0.9;
	}
}
.overlay-wraper{ 
	>.container,
	>.container-fluid,
	>.wt-icon-box-wraper,
	>.wt-left-part,
	>.wt-right-part {
		position: relative;
		z-index: 1;
	}
}

/*===Boxes Css===*/
.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
	position: relative;
}
/*---Box content----*/
.wt-box {
	position: relative;
	&.no-margin {
		margin-bottom: 0;
	}
}
/*---For icons with box---*/

.wt-icon-box-xld {
	width: 180px;
	height: 180px;
	display: inline-block;
	text-align: center;
	i {
		font-size: 100px;
	}
	img {
		height: 90px;
	}
}
.wt-icon-box-xl {
	width: 150px;
	height: 150px;
	display: inline-block;
	text-align: center;
	i {
		font-size: 80px;
	}
	img {
		height: 80px;
	}
}
.wt-icon-box-lg {
	width: 120px;
	height: 120px;
	display: inline-block;
	text-align: center;
	i {
		font-size: 60px;
	}
	img {
		height: 55px;
	}
}
.wt-icon-box-md {
	width: 100px;
	height: 100px;
	display: inline-block;
	text-align: center;
	i {
		font-size: 45px;
	}
	img {
		height: 40px;
	}
}
.wt-icon-box-sm {
	width: 80px;
	height: 80px;
	display: inline-block;
	text-align: center;
	i {
		font-size: 30px;
	}
	img {
		height: 30px;
	}
}
.wt-icon-box-xs {
	width: 40px;
	height: 40px;
	display: inline-block;
	text-align: center;
	i {
		font-size: 20px;
	}
	img {
		height: 20px;
	}
}


.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs{
	&.radius{
		border-radius: 100%;
	}
	i{vertical-align: middle;}
	img{vertical-align: middle;	max-width: 100%;	width: auto;}
}

/*----For only icons---*/
.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
	display: inline-block;
	text-align: center;
	i{vertical-align: middle;}
	img{
		vertical-align: middle;
		max-width: 100%;
		width: auto;
	}
}
.icon-xld {
	width: 120px;
	i{font-size: 100px;	line-height:100px;}
	&.inline-icon {	width: auto;	text-align: left !important;}
	img{height: 90px;}
	&.inline-icon {	width:auto;}
}

.icon-xl{
	width: 100px;
	i{font-size: 80px;	line-height:80px;}
	&.inline-icon {	width: auto;	text-align: left !important;}
	img{height: 80px;}
	&.inline-icon {	width:auto;}	
}
.icon-lg{
	width: 80px;
	i{font-size: 60px;	line-height:60px;}
	&.inline-icon {	width: auto;	text-align: left !important;}
	img {	height: 70px;}
	&.inline-icon {	width:auto;}	
}
.icon-md{
	width: 60px;
	i{font-size: 45px;	line-height:45px;}
	&.inline-icon {	width: auto;	text-align: left !important;}
	img {	height: 50px;}
	&.inline-icon {	width:auto;}	 
}

.icon-sm{
	width: 40px;
	i{font-size: 30px;	line-height:30px;}
	&.inline-icon {	width: auto;	text-align: left !important;}
	img {	height: 30px;}
	&.inline-icon {	width:auto;}	
}
.icon-xs{
	width: 30px;
	i{font-size: 20px;	line-height:20px;}
	&.inline-icon {	width: auto;	text-align: left !important;}
	img {	height: 20px;}
	&.inline-icon {	width:auto;}
}
/*---Place icon with box---*/
.wt-icon-box-wraper {
	position: relative;
	.wt-tilte {
		margin-top: 0;
	}
	.after-titile-line {
		margin-bottom: 10px;
	}
	p {
		&:last-child {
			margin: 0px;
		}
	}
}
.icon-content {	overflow: hidden;}

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
	display: table;
	.icon-cell{
		display: table-cell;
		vertical-align: middle;
	}
}

/*---Icon box left align---*/
.wt-icon-box-wraper{
	&.left{
		.wt-icon-box-xld,
		.wt-icon-box-xl,
		.wt-icon-box-lg,
		.wt-icon-box-md,
		.wt-icon-box-sm,
		.wt-icon-box-xs{
			float: left;
			margin-right: 10px;
		}
	}
}
.wt-icon-box-wraper{
	&.left{
		.icon-xld,
		.icon-xl,
		.icon-lg,
		.icon-md,
		.icon-sm,
		.icon-xs {
			float: left;
			margin-right: 10px;
		}
	}
}
/*---Icon box right align---*/
.wt-icon-box-wraper{
	&.right{
		text-align: right;
		.wt-icon-box-xld,
		.wt-icon-box-xl,
		.wt-icon-box-lg,
		.wt-icon-box-md,
		.wt-icon-box-sm,
		.wt-icon-box-xs{
			float: right;
			display: table;
			margin-left: 20px;
		}
	}
}

.wt-icon-box-wraper{
	&.right{
		.icon-xld,
		.icon-xl,
		.icon-lg,
		.icon-md,
		.icon-sm,
		.icon-xs {
			float: right;
			margin-left: 20px;
		}
	}
}

/*---Icon box center align---*/
.wt-icon-box-wraper{
	&.center{
		text-align: center;
		.wt-icon-box-xld,
		.wt-icon-box-xl,
		.wt-icon-box-lg,
		.wt-icon-box-md,
		.wt-icon-box-sm,
		.wt-icon-box-xs{
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.wt-icon-box-wraper.bx-style-1 {
	border-width: 1px;
	border-style: solid;
	border-color: #ddd;
}
.wt-icon-box-wraper.bx-style-2 {
	border-width: 1px;
	border-style: solid;
	border-color: #ddd;
	&.center{
		[class*="wt-icon-box-"] {
			position: absolute;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateY(-50%);
		}
	}
}

.wt-icon-box-wraper.bx-style-2.left {
	[class*="wt-icon-box-"] {
		position: absolute;
	}
}
.wt-icon-box-wraper.bx-style-2.right {
	[class*="wt-icon-box-"] {
		position: absolute;
	}
}

/*---Buttons--- */
.site-button-link {
	position: relative;
	color: $clr-primary;
	font-size: 18px;
	z-index: 1;
	transition: $transition;
	text-transform: uppercase;
	overflow: hidden;
	&:after{
		content: '\f105';
		position: absolute;
		font-family:'FontAwesome';
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0px;
		transition: $transition;
		opacity: 0;
	}
	&:hover{
		padding-left: 20px;
		color:$clr-black;
		&:after{
			font-size: 22px;
			left: 0px;
			opacity: 1;
		}
	}
	&.site-text-white{
		color: $clr-white;
		&:hover{
			color: $clr-dark;	
		}
	}

}
/* Theme default button */

.site-button {
	outline: none;
	color:$clr-white;
	padding: 15px 30px;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: inline-table;
	background-color:$clr-primary ;
	border: none;
	font-size: 15px;
	font-weight:700;
	transition: $transition;
	
	&:hover{
		color: $clr-black;		
	}
	i{
		padding-right: 10px;
	}
	@include breakpoint-480{
		padding: 10px 15px;
	}
}



.btn-hover-animation {
	z-index: 1;
	overflow: hidden;
	display: inline-block;
  }
  .btn-hover-animation:before {
	content: "";
	position: absolute;
	z-index:-1;
	top: -4px;
	left: -14px;
	background: rgba(0, 0, 0, 0.1);
	height: 50px;
	width: 50px;
	border-radius: 50px;
	
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: transform 1s ease-out;
	transition: transform 1s ease-out,
  }
  .btn-hover-animation:hover:before {
	transform: scale(8);
  }
  
  


.site-button-secondry {
	color: $clr-white;
	padding: 15px 40px;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: inline-block;
	background-color: $clr-black;
	border: none;
	font-size: 15px;
	font-weight:700;
	&.sb-bdr-light{border-right: 6px solid $clr-white;}
	&:active,&:focus,&:visited{
		background-color:$clr-black;
		color: $clr-white;
	}
	&:hover{
		background-color:$clr-gray;
		color: $clr-black;
	}
}

/*====Button Effect====*/
.site-btn-effect {
	color: rgba(0, 0, 0, 0) !important;
	transition: all .3s ease !important;
}

.site-button{
	&.site-btn-effect {
		text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
		&:hover{
			text-shadow: -400px 0 0 #000, 0 0 0 #000;
		}
	}
}

.site-button-secondry{
	&.site-btn-effect {
		text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
	}
	&:hover{
		&.site-btn-effect{
		   text-shadow: -400px 0 0 #fff, 0 0 0   #fff;	
		}
	}
}

.mfp-video.video-play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	margin: -25px;
	color: $clr-white;
	display: block;
	z-index: 10;
	font-size: 14px;
	border: 2px solid $clr-white;
	border-radius: 50%;
	&:hover{
		background-color: #0f1221;
		color: #fff;
		border: 2px solid transparent;
	}
	i {	margin-right: -5px;}
}


/*Button text uppercase */
.site-button{
	&.text-uppercase{text-transform: uppercase;}
}

/*Button size */
.button-sm {padding: 5px 10px;	font-size: 12px;}
.button-lg {padding: 15px 30px;	font-size: 16px;}
.button-xl {padding: 30px 50px;	font-size: 24px;}

/*Button rounded */
.radius-no {border-radius: 0px;}
.radius-sm {border-radius: 10px;}
.radius-md {border-radius: 20px;}
.radius-xl {border-radius: 100px;}
.radius-bx {border-radius: 100%;}

/*===Owl Slider===*/
.owl-carousel{
	.owl-nav {
		margin-top:40px;
		text-align: center;
		.disabled {
			opacity: .5;
			cursor: default
		}  	
	}
}

/*---Owl dots button---*/
.owl-carousel {
	.owl-dots {
		text-align: center;
		margin-top: 30px;
		.owl-dot {
			display: inline-block;
			span {
				width: 6px;
				height: 6px;
				margin: 5px 7px;
				background: #051026;
				border: 2px solid transparent;
				display: block;
				border-radius: 50%;
				transition: opacity 200ms ease;
			}
			&:hover {
				span {
					background: #000;
				}
			}
			&.active{
				span{
					background: $none;
					border: 2px solid #051026;
					margin: 2px;
					height: 12px;
					width: 12px;
				}
			}
		}
		.owl-dot.active {
			span {
				background: $clr-white;
			}
		}
	}
}

/*---Owl button top to center---*/
.owl-btn-top-center {
	.owl-nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}
}
.owl-btn-top-left {
	.owl-nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}
}
.owl-btn-top-right {
	.owl-nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}
}

.owl-btn-top-center {
	.owl-stage-outer {
		padding-top: 60px;
	}
}
.owl-btn-top-left {
	.owl-stage-outer {
		padding-top: 60px;
	}
}
.owl-btn-top-right {
	.owl-stage-outer {
		padding-top: 60px;
	}
}

.owl-btn-top-center {
	.owl-nav {
		text-align: center;
	}
}

/*---Owl button top to left---*/
.owl-btn-top-left {
	.owl-nav {
		text-align: left;
	}
}
/*---Owl button top to right---*/
.owl-btn-top-right {
	.owl-nav {
		text-align: right;
	}
}
/*---Owl button bottom to center [it is default position ]---*/
.owl-btn-bottom-center {
	.owl-nav {
		text-align: center;
	}
}
/*---Owl button bottom to left---*/
.owl-btn-bottom-left {
	.owl-nav {
		text-align: left;
	}
}

/*Owl button bottom to right*/
.owl-btn-bottom-right{
	.owl-nav {
		text-align:right;
	}
}
/*---Owl button vertical to center--*/
.owl-btn-vertical-center {
	.owl-nav {
		margin: 0px;
		.owl-prev {
			position: absolute;
			top: 50%;
			margin: -15px 0;
			left: 0;
		}
		.owl-next {
			position: absolute;
			top: 50%;
			margin: -15px 0;
			right: 0;
		}
	}
}
/*---Owl button show on hover---*/
.owl-btn-hover {
	.owl-nav {
		opacity: 0;
	}
	&:hover {
		.owl-nav {
			opacity: 1;
		}
	}
}
.owl-carousel {
	.owl-item {
		img {
			transform-style: inherit;
		}
	}
}

/*---Owl slider button---*/
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot{

	background-color:$clr-dark;
	color: $clr-white;
	padding: 8px 16px !important;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	cursor: pointer;
	border-radius: 0px;
	opacity: 1;
	margin-right: 5px;
	font-weight: 500;
	i{
		font-style: normal;
	}
	&:hover {
		background-color: $clr-primary;
		color: $clr-white;
		opacity: 0.9;
	}
	&:active {
		background-color: $clr-primary;
		color: $clr-white;
		opacity: 0.9;
	}
	&:focus {
		background-color: $clr-primary;
		color: $clr-white;
		opacity: 0.9;
	}
}

.owl-btn-bottom-left{
	.owl-nav{
		text-align: left;
	}
	
}

/*---Owl dots nav position---*/
.owl-dots-bottom-left {
	.owl-dots {
		bottom: 0px;
		padding: 15px 20px;
		position: absolute;
		width: 100%;
		text-align: left;
	}
}
.owl-dots-bottom-right {
	.owl-dots {
		bottom: 0px;
		padding: 15px 20px;
		position: absolute;
		width: 100%;
		text-align: right;
	}
}
.owl-dots-bottom-center {
	.owl-dots {
		bottom: 0px;
		padding: 15px 20px;
		position: absolute;
		width: 100%;
	}
}



/*-------------------------------------
	Text meant only for screen readers
-------------------------------------*/
.sticky {
	clear: both;
}
.gallery-caption {
	clear: both;
}
.bypostauthor {
	clear: both;
}
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	&:focus {
		background-color: #f1f1f1;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: #21759b;
		display: block;
		font-size: 14px;
		font-size: 0.875rem;
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
	}
}


/*-------------------------------------
	Tabs 
-------------------------------------*/
/*Tabs style 1 [ default ] */
.wt-tabs { 
	.tab-pane {
		padding: 20px 0px 0px 0px;
		background-color: $clr-white;
	}
	.nav-tabs {
		border: 0px;
		>li {
			border: 1px solid #ebebeb;
			>a {
				color: $clr-black;
				background-color: $clr-white;
				font-size: 16px;
				padding: 15px 40px;
				border: 0px;
				display: block;
				font-weight: 600;;
				i {
					margin-right: 5px;
				}
			}
			a.active {
				background-color: $clr-gray;
				color: $clr-black;
				&:focus {
					background-color: $clr-gray;
					color:  $clr-black;
				}
				&:hover {
					background-color: $clr-gray;
					color:  $clr-black;
				}
			}
		}
	}
	.nav {
		>li {
			>a {
				&:focus {
					background-color: transparent;
					border: 0px;
				}
				&:hover {
					background-color: transparent;
					border: 0px;
				}
			}
		}
		li {
			a {
				&:hover {
					border: 1px;
				}
			}
		}
	}
}
.wt-tabs.tabs-default{
	.tabs-default-nav{
		.nav-tabs{
			display: flex;
			justify-content: flex-end;
			@include breakpoint_767{
				justify-content: flex-start;
			}
			>li {
				>a {
					color: $clr-black;
					span{display: block; 
						i{font-weight: normal;}
					}
				&.active{
					color: $clr-white;
				}
				}
			}
			>li.active {
				>a {
					color:  $clr-black;
					border-color: #ddd #ddd #fff;
					&:focus {
						color:  $clr-black;
						border-color: #ddd #ddd #fff;
					}
					&:hover {
						color:  $clr-black;
						border-color: #ddd #ddd #fff;
					}
				}
			}
		}
	}
	.tab-pane {
		color: #fff;
		@include breakpoint_991{
			padding: 0px;
		}
	}
}


