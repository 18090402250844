@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import "public/assets/css/media-queries";
@import "public/assets/css/variables";
@import "public/assets/css/general";
@import "public/assets/css/header3";

@media (min-width: 1200px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1170px;
	}
}

/*Title separators*/

.wt-small-separator {
	font-family: $heading-font;
	margin-bottom: 20px;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 5px;
	color: $clr-primary;
	font-size: 40px;
	line-height: 40px;
	font-weight: 600;
	opacity: 1;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke: 1px $clr-primary;

	&.white {
		color: $clr-white;
	}

	@include breakpoint_991 {
		font-size: 27px;
		margin-bottom: 10px;
		letter-spacing: 3px;
	}

	h2 {
		@include breakpoint_767 {
			margin-bottom: 20px;
		}
	}
}

.wt-separator-two-part-row {
	align-items: center;
}

.wt-separator-two-part-right {
	@include breakpoint-991 {
		text-align: left;
		margin-bottom: 30px;
	}
}

/*Center title*/
.section-head {
	&.left {
		&.wt-small-separator-outer {
			text-align: left;

			h2 {
				margin-bottom: 35px;

				@include breakpoint_991 {
					font-size: 30px;
				}

				@include breakpoint_768 {
					font-size: 30px;
					margin-bottom: 20px;
				}
			}

			p {
				margin-bottom: 40px;
			}
		}
	}

	&.center {
		&.wt-small-separator-outer {
			margin-bottom: 50px;
			text-align: center;
			max-width: 710px;
			margin-left: auto;
			margin-right: auto;

			.section-head-text {
				margin: 20px 0px 0px 0px;
			}

			h2 {
				max-width: 710px;
				margin: 0px auto;

				@include breakpoint_991 {
					font-size: 30px;
				}

				@include breakpoint_768 {
					font-size: 30px;
					margin-bottom: 20px;
				}
			}

			.section-head-text {
				max-width: 660px;
				margin-left: auto;
				margin-right: auto;
			}
		}

	}

	.section-head-text {
		font-size: 14px;
	}
}

/*Company approch*/
.tw-company-approch-section {
	position: relative;
	margin-top: 100px;
	margin-bottom: 90px;

	@include breakpoint-575 {
		margin-top: 30px;
		margin-bottom: 50px;
	}

	&:before {
		position: absolute;
		content: '';
		top: -60px;
		bottom: -60px;
		left: 0px;
		border-style: solid;
		border-color: #ff8a00;
		border-width: 60px 0px 60px 60px;
		width: 300px;
		z-index: 0;

		@include breakpoint-575 {
			top: -20px;
			bottom: -20px;
			left: 0px;
			border-width: 20px 0px 20px 20px;
			width: 200px;
		}
	}

	.tw-company-approch-inner {
		position: relative;
		padding: 50px;
		margin-left: 50px;
		background-color: $clr-white;
		box-shadow: 0px 0px 50px rgba(30, 143, 208, 0.5);
		z-index: 1;
		background-image: url(../public/assets/images/background/waves.png);
		background-position: right bottom;
		background-repeat: no-repeat;

		@include breakpoint-575 {
			margin-left: 20px;
			padding: 30px;
		}

		@include breakpoint-991 {
			.row {

				[class*='col-']:last-child {
					.counter-outer-two {
						margin-bottom: 0px;
					}
				}

			}
		}
	}
}

/*Counter*/
.counter-outer-two {
	position: relative;

	@include breakpoint-991 {
		margin-bottom: 30px;
	}

	.tw-counter-media {
		position: absolute;
		bottom: 0px;
		left: 0px;

		img {
			height: 70px;

			@include breakpoint-991 {
				height: 46px;
			}
		}

		@include breakpoint-991 {
			img {
				height: 46px;
			}
		}
	}

	.tw-count-number {
		font-size: 46px;
		line-height: 46px;
		margin-bottom: 15px;
		font-weight: 600;
		font-family: $heading-font;

		@include breakpoint_991 {
			font-size: 30px;
			line-height: 30px;
		}
	}

	.counter {
		font-size: 46px;
		line-height: 50px;
		font-weight: 600;
		font-family: $heading-font;

		@include breakpoint_991 {
			font-size: 30px;
			line-height: 30px;
		}

		@include breakpoint_768 {
			font-size: 24px;
		}
	}

	.icon-content {
		overflow: hidden;
		padding-left: 100px;

		@include breakpoint-991 {
			padding-left: 80px;
		}
	}

	.icon-content-info {
		font-weight: 600;
		color: $clr-dark;
		margin-bottom: 0px;
	}

}

.one-column1,
.one-column2 {
	.wt-media {
		@include breakpoint_767 {
			margin-bottom: 20px;
		}

		img {
			width: 100%;
		}
	}
}

/*Client carousel 1*/
.home-client-carousel {
	padding: 30px 10px 10px 10px;
	background-color: $clr-primary;
	z-index: 1;
	position: relative;
	bottom: -70px;
	left: 0px;

	@include breakpoint-991 {
		bottom: 0px;
	}

	&:after,
	&:before {
		position: absolute;
		content: '';
		width: 60px;
		height: 60px;
	}

	&:after {
		right: 10px;
		bottom: -10px;
		border-bottom: 1px solid #000;
		border-right: 1px solid #000;
	}

	&:before {
		left: -10px;
		top: 10px;
		border-top: 1px solid #fff;
		border-left: 1px solid #fff;
	}

	&.owl-carousel {
		.owl-dots {
			margin-top: 10px;
		}
	}

	.client-logo {
		a {
			img {
				width: auto;
				height: 70px;
				margin: auto;
			}
		}
	}
}

/*Client carousel 2*/
.home-client-carousel2 {
	padding: 0px;

	.client-logo {
		max-width: 130px;
		margin-left: auto;
		margin-right: auto;

		a {
			height: 100px;
			display: flex;
			justify-content: space-around;

			img {
				width: auto;
				height: auto;
				filter: grayscale(100%);
				transition: $transition;
			}

			&:hover {
				img {
					filter: none;
				}
			}
		}

	}
}

/*Footer News Letter*/
.ftr-nw-ltr {
	padding-top: 200px;

	.ftr-nw-ltr-inner {
		padding: 25px 0px;

		.container {
			position: relative;
		}

		.ftr-nw-img {
			position: absolute;
			left: 0px;
			bottom: -25px;

			@include breakpoint_767 {
				bottom: auto;
				top: 0px;
				position: relative;
				padding-bottom: 20px;
			}
		}

		.ftr-nw-content {
			display: flex;
			justify-content: end;

			@include breakpoint_767 {
				margin-left: 0px;
			}

			.ftr-nw-title {
				font-size: 40px;
				line-height: 1.2;
				font-family: $heading-font;
				color: $clr-dark;
				font-weight: 600;
				max-width: 320px;
				width: 100%;
				margin-right: 30px;

				@include breakpoint-768 {
					font-size: 30px;
				}
			}

			@include breakpoint-991 {
				// display: block;

				.ftr-nw-title {
					margin-right: 0px;
					max-width: 100%;
					margin-bottom: 20px;
				}
			}

			.ftr-nw-form {
				max-width: 476px;
				width: 100%;

				.form-control {
					height: 66px;
					margin-bottom: 10px;
					border: 0px;
				}

				.ftr-nw-subcribe-btn {
					font-size: 22px;
					color: $clr-white;
					background-color: $clr-dark;
					border: none;
					font-family: $heading-font;
					font-weight: 600;
					padding: 9px 30px;

					@include breakpoint-768 {
						font-size: 16px;
						padding: 7px 20px;
					}
				}
			}
		}
	}

	@include breakpoint_991 {
		padding-top: 105px;
	}

	@include breakpoint_768 {
		padding-top: 160px;
	}

	@include breakpoint_767 {
		padding-top: 0px;

		.ftr-nw-ltr-inner {
			padding: 40px 0px;
		}
	}
}

/*Footer dark version css*/
.footer-dark {
	color: $clr-white;
	font-size: 16px;

	.ftr-bg {
		background-color: $clr-dark;
		background-repeat: no-repeat;
		background-size: cover;

		@include breakpoint_767 {
			background-size: auto;
			background-repeat: repeat;
		}
	}

	.logo-footer {
		margin-bottom: 25px;
		max-width: 174px;
	}

	.footer-top {
		padding: 80px 0px 0px 0px;

		@include breakpoint_991 {
			padding: 30px 0px 0px 0px;
		}
	}

	.widget-title {
		font-family: $common-font;
		color: $clr-white;
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 30px;
		padding-bottom: 23px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
			background-color: $clr-primary;
			width: 60px;
			height: 3px;
		}
	}

	.social-icons {
		margin: 40px 0px 0px 0px;

		li {
			display: inline-block;

			a {
				height: 45px;
				width: 45px;
				background-color: #184f6e;
				line-height: 45px;
				padding: 0px;
				color: $clr-white;
				font-size: 22px;
				text-align: center;
				margin-right: 3px;

				&:hover {
					color: $clr-white;
					background-color: #051721;
				}
			}
		}
	}

	.footer-bottom {
		padding: 0px;
		color: $clr-white;
		position: relative;
		z-index: 1;
		font-weight: 400;

		.footer-bottom-info {
			display: flex;
			padding: 30px 0px;
			justify-content: center;
			align-items: center;
			border-top: 1px solid rgba(255, 255, 255, 0.1);

			@include breakpoint_991 {
				display: block;
			}

		}

		.footer-copy-right {
			@include breakpoint_991 {
				margin: 5px 10px 5px 0px;
				display: inline-block;
			}

			.copyrights-text {
				color: $clr-white;
				font-size: 14px;
			}
		}

	}
}

.widget {
	margin-bottom: 40px;
}

.ftr-list-center {
	display: grid;
	justify-content: center;

	@include breakpoint-991 {
		justify-content: left;
	}
}

/*Recent Post Entry*/
.recent-posts-entry {
	.widget-post {
		&:last-child {
			border: none;
			margin-bottom: 0px;
		}

		margin-bottom: 20px;
	}

	.wt-post-date {
		background-color: #0e0e0e;
		width: 50px;
		height: 60px;
		float: left;

		strong {
			display: block;
			font-size: 24px;
		}

		span {
			display: block;
			font-size: 11px;
			text-transform: uppercase;
			font-weight: 600;
		}
	}

	.wt-post-media {
		width: 90px;
		float: left;

		@include breakpoint_1024 {
			width: 60px;
		}
	}

	.wt-post-info {
		margin-left: 105px;

		@include breakpoint_1024 {
			margin-left: 70px;
		}

		.wt-post-header {
			margin-bottom: 6px;
		}

		.wt-post-meta {
			ul {
				list-style: none;
				margin-bottom: 0px;

				li {
					font-size: 12px;
					line-height: 16px;
					color: $clr-primary;
				}
			}
		}

		.post-title {
			margin-top: 0px;
		}
	}
}

/*Footer Dark*/
.footer-dark {
	.recent-posts-entry {
		.wt-post-info {
			.wt-post-header {
				a {
					color: $clr-white-bg-dark;
				}
			}
		}
	}
}

/*Widget Services*/
.widget_services {
	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		li {
			display: block;
			width: 100%;
			padding: 2px 0px;
			border: 0px;
			overflow: hidden;

			a {
				color: $clr-white;
				position: relative;
				padding: 0px 0px 5px 0px;
				display: block;
				margin-left: 0px;
				transition: $transition;
				font-size: 16px;

				&:hover {
					color: $clr-primary;
				}
			}
		}
	}
}

/*scroll top btn css*/
button {
	&.scroltop {
		height: 40px;
		width: 40px;
		background: #1d1b18;
		border: none;
		position: fixed;
		right: 15px;
		bottom: 15px;
		text-transform: uppercase;
		margin: 0;
		padding: 0;
		cursor: pointer;
		display: none;
		text-align: center;
		z-index: 999;
		color: $clr-white;

		span {
			display: block;
			font-size: 24px;
			line-height: 24px;
		}
	}
}

/*Pricing Table*/
.pricing-table-1 {
	.p-table-title {
		position: relative;
		text-align: right;

		.wt-title {
			font-size: 22px;
			margin-bottom: 0px;
			background-color: $clr-sky-blue;
			color: $clr-white;
			text-transform: uppercase;
			display: inline-block;
			padding: 10px 25px;
		}
	}

	.p-table-inner {
		position: relative;
		padding: 40px;
		border: 1px solid $clr-sky-blue;

		.p-table-list {
			ul {
				list-style: none;
				margin-bottom: 40px;

				li {
					position: relative;
					font-size: 16px;
					margin-bottom: 5px;
					padding-left: 20px;

					&:after {
						content: '\f105';
						font-family: 'FontAwesome';
						color: $clr-primary;
						left: 0px;
						top: 0px;
						position: absolute;
					}
				}
			}
		}

		.p-table-price {
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			color: $clr-dark;

			span {
				font-family: $heading-font;
				font-size: 45px;
				line-height: 56px;
				font-weight: 600;
				display: block;
				padding-right: 15px;

				@include breakpoint_991 {
					font-size: 36px;
					line-height: 26px;
				}
			}

			p {
				font-size: 22px;
				margin-bottom: 0px;
				font-family: $heading-font;
			}
		}
	}
}

.p-table-highlight {
	.p-table-inner {
		background-color: $clr-sky-blue;

		.p-table-price {
			color: $clr-white;
		}

		.p-table-list {
			ul {
				li {
					color: $clr-white;
				}
			}
		}
	}
}

/*inner page banner*/
.wt-bnr-inr {
	height: 510px;
	background-size: cover;
	background-position: center center;
	display: table;
	width: 100%;
	position: relative;

	@include breakpoint-767 {
		height: 380px;
	}

	&:after {
		content: '';
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		position: absolute;
		background: linear-gradient(to bottom, rgba(30, 143, 208, 1) 0%, rgba(30, 143, 208, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e8fd0', endColorstr='#001e8fd0', GradientType=0);
	}

	.container {
		display: table;
		height: 100%;
	}

}

.wt-bnr-inr-entry {
	display: table-cell;
	vertical-align: bottom;
	text-align: left;
	padding-bottom: 10px;

	.banner-title-outer {
		position: relative;

		.wt-title {
			font-size: 66px;
			margin-bottom: 0px;
			position: relative;

			@include breakpoint_767 {
				font-size: 40px;
			}
		}

		.banner-title-name {
			display: inline-block;
			margin-bottom: 50px;

			.wt-title {
				color: $clr-white;
			}
		}
	}
}

/*Breadcrumb*/
.wt-breadcrumb {
	margin: 0;
	display: inline-block;
	list-style: none;
	position: relative;

	&:before {
		content: '';
		right: -15px;
		background-color: $clr-gray-light;
		position: absolute;
		bottom: -11px;
		width: 6000px;
		height: 60px;
	}

	li {
		padding-right: 30px;
		position: relative;
		display: inline-block;
		font-size: 18px;
		font-family: $common-font;
		font-weight: 600;
		color: $clr-primary;
		text-transform: uppercase;

		a {
			color: $clr-dark;

			&:hover {
				color: $clr-primary;
			}
		}

		&:after {
			content: '//';
			position: absolute;
			right: 8px;
			top: 3px;
			color: $clr-dark;
			font-size: 12px;
			font-weight: 600;
		}

		&:last-child {
			color: $clr-primary;
			padding-right: 0px;

			&:after {
				display: none;
			}
		}

		&:first-child {
			a {
				i {
					font-size: 18px;
					vertical-align: text-top;
				}
			}
		}
	}

}

/*Contact Form*/
.single-contact-card {
	background-color: #f8f8f8;
	padding: 40px;
	margin-bottom: 30px;
	transition: all 0.3s ease-in-out;

	@media (max-width: 585px) {
		padding: 30px;
	}

	&:hover {
		background-color: #202426;

		.icon {
			color: #fff !important;
			background-color: $clr-sky-blue !important;
		}

		.bottom-part {
			.icon {
				color: $clr-sky-blue !important;
				background-color: #fff !important;
			}
		}

		p,
		span {
			color: #c5c7c8;
		}

		h4 {
			color: #fff;
		}
	}

	.top-part {
		overflow: hidden;

		.icon {
			width: 70px;
			height: 70px;
			font-size: 20px;
			line-height: 70px;
			border-radius: 50%;
			background-color: #fff;
			text-align: center;
			float: left;
			color: $clr-sky-blue;
			overflow: hidden;
			margin-right: 20px;
		}

		.title {
			overflow: hidden;
		}
	}

	.bottom-part {
		margin-top: 25px;
		overflow: hidden;

		.icon {
			width: 50px;
			height: 50px;
			font-size: 14px;
			line-height: 50px;
			border-radius: 50%;
			background-color: #fff;
			text-align: center;
			float: right;
			overflow: hidden;
			margin-left: 20px;
		}

		.info {
			display: inline-block;
			overflow: hidden;
		}
	}
}

.section-head-small {
	font-size: 22px;
	border-left: 4px solid $clr-primary;
	padding-left: 20px;

	&.white {
		.wt-title {
			color: $clr-white;
		}
	}
}

.contact-form-outer {
	padding: 40px 25px;
}

.cons-contact-form {
	.form-control {
		height: 52px;
		padding: 10px 20px;
		background-color: $clr-white;
		border: 0px;

		&:focus {
			box-shadow: none;
			background-color: $clr-gray;
		}
	}

	textarea.form-control {
		padding-top: 15px;
		height: 80px;
		margin-bottom: 40px;
		resize: none;
	}
}

.form-control {
	height: 50px;
	font-size: 13px;
	line-height: 20px;
	padding: 10px 12px;
	border-radius: 0px;

	&:focus {
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
		background-color: #F8F8F8;
	}
}

/*Pagination Style*/
.pagination-outer {
	padding-top: 30px;

	@include breakpoint_991 {
		margin-bottom: 30px;
	}

	.pagination-style1 {
		position: relative;
		display: inline-block;

		ul {
			list-style: none;
			margin-bottom: 0px;

			li {
				position: relative;
				margin: 0px 3px 0px;
				display: inline-block;

				&.prev {
					a {
						width: 40px;
						height: 40px;
						font-size: 16px;
						color: $clr-dark;
						font-weight: 700;
						transition: $transition;
						letter-spacing: 1px;

						@include breakpoint_420 {
							width: 30px;
							height: 30px;
						}
					}
				}

				&.next {
					a {
						width: 40px;
						height: 40px;
						font-size: 16px;
						color: $clr-dark ;
						font-weight: 700;
						transition: $transition;
						letter-spacing: 1px;

						@include breakpoint_420 {
							width: 30px;
							height: 30px;
						}
					}
				}

				a {
					position: relative;
					width: 40px;
					height: 40px;
					color: $clr-dark;
					font-size: 16px;
					line-height: 40px;
					text-align: center;
					display: inline-block;
					transition: $transition;
					font-family: $heading-font;
					border: 1px solid #ddd;
					font-weight: 800;

					@include breakpoint-480 {
						width: 30px;
						height: 30px;
						line-height: 30px;
					}
				}

				&.active {
					a {
						background-color: $clr-primary;
						color: $clr-white;
						border: 1px solid $clr-primary;
					}
				}

				&:hover {
					a {
						background-color: $clr-primary;
						border: 1px solid $clr-primary;
					}
				}
			}
		}
	}
}

/*Blog Side Bar*/
.side-bar {
	.widget {
		&:last-child {
			margin-bottom: 0px;
		}

		.form-control {
			background-color: $clr-gray-light;
		}

		.widget-title {
			position: relative;
			padding-bottom: 15px;
			text-align: center;

			&:before {
				content: '';
				position: absolute;
				bottom: 0px;
				left: 50%;
				transform: translateX(-50%);
				background-color: $clr-primary;
				width: 20px;
				height: 2px;
			}
		}
	}

	//search box
	.search-bx {
		.input-group {
			.form-control {
				height: 80px;
				background-color: $clr-white;
				font-size: 20px;
				padding: 0px 25px;

				@include breakpoint-767 {
					height: 60px;
				}
			}

			.btn {
				background-color: $clr-primary;
				color: $clr-white;
				border-radius: 0px;
				height: 80px;
				padding: 0px 30px;
				font-size: 30px;

				@include breakpoint-767 {
					height: 60px;
					padding: 0px 15px;
				}
			}
		}
	}


	//widget services
	.widget_services {
		ul {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			background-color: $clr-white;

			li {
				position: relative;
				padding: 10px 0px 10px 15px;
				line-height: 20px;
				font-size: 14px;
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;

				&:after {
					content: '';
					width: 4px;
					height: 5px;
					border-radius: 50%;
					background-color: $clr-primary;
					position: absolute;
					left: 0px;
					top: 17px;
				}

				&:last-child {
					padding-bottom: 0px;
				}

				a {
					color: $clr-dark;
					margin-left: 0px;
					padding: 0px;
					display: inline-block;
					font-family: $heading-font;
					font-size: 14px;
					font-weight: 600;

					&:hover {
						color: $clr-primary;
					}

					i {
						padding-right: 5px;
					}

					&:before {
						display: none;
					}
				}

				.badge {
					background: none;
					font-size: 14px;
					font-weight: 600;
				}

			}
		}
	}

	//recent post
	.recent-posts-entry {
		.widget-post {
			margin-bottom: 20px;

			&:last-child {
				border: none;
				margin-bottom: 0px;
			}
		}

		.wt-post-media {
			width: 108px;
			float: left;

			@include breakpoint_420 {
				width: 80px;
			}

			img {
				border-radius: 0px;
			}
		}

		.wt-post-info {
			margin-left: 130px;

			@include breakpoint_420 {
				margin-left: 95px;
			}

			.post-date {
				margin-top: 0px;
				color: $clr-primary;
				font-size: 16px;
				display: block;
			}

			.post-title {
				margin-top: 0px;
				font-family: $heading-font;
				font-size: 18px;
				font-weight: 500;
				display: block;
			}
		}

	}

}

.tw-sidebar-tags-wrap {
	.tagcloud {
		margin-top: -10px;

		a {
			padding: 6px 15px;
			margin-top: 10px;
			margin-right: 10px;
			display: inline-block;
			color: $clr-dark;
			font-family: $common-font;
			font-size: 14px;
			background-color: $clr-gray;
			text-transform: uppercase;
			font-weight: 500;

			&:hover {
				background-color: $clr-primary;
				color: $clr-white;
			}
		}
	}
}

ol.comment-list {
	list-style: none;
	background-color: #f1f1f1;
	padding: 30px;

	li.comment {
		position: relative;
		padding: 0;

		.comment-body {
			padding: 0px 90px 20px 120px;
			position: relative;
			margin-bottom: 30px;
			overflow: hidden;
			min-height: 130px;

			@include breakpoint_480 {
				padding: 0px 0px 50px 0px;
			}
		}

		.comment-author {
			display: inline-block;
			margin-bottom: 5px;
			position: absolute;
			top: 0px;
			left: 0px;

			@include breakpoint_480 {
				position: inherit;
				margin-bottom: 20px;
			}

			.avatar {
				width: 100px;
				height: 100px;
				transition: all .5s ease-in-out;
				padding: 5px;
				border: 1px solid $clr-dark;

			}

		}

		.fn {
			display: inline-block;
			color: $clr-dark;
			font-size: 18px;
			font-weight: 500;
			font-style: normal;
			font-family: $heading-font;

			@include breakpoint_480 {
				display: block;
			}
		}

		.comment-meta {
			margin-bottom: 5px;
			display: block;
			background-color: $clr-dark;
			text-align: center;

			a {
				color: $clr-white;
				font-size: 12px;
				text-align: center;
			}
		}

		.reply {
			position: absolute;
			right: 0px;
			top: 0px;

			@include breakpoint_480 {
				top: auto;
				right: auto;
				bottom: 10px;
				left: 0px;
			}

			.comment-reply-link {
				position: relative;
				color: $clr-white;
				background-color: $clr-primary;
				padding: 4px 12px;
				font-size: 14px;
				display: inline-block;

				&:hover {
					color: $clr-white;
					background-color: $clr-black;
				}
			}
		}

		p {
			margin: 0px;
			font-weight: 400;
		}
	}

	li {
		.children {
			list-style: none;
			margin-left: 40px;

			@include breakpoint_767 {
				margin-left: 0px;
			}

			li {
				padding: 0;

				&:last-child {
					.comment-body {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}

/*comment form*/
.comment-respond {
	@include breakpoint-991 {
		margin-bottom: 40px;
	}

	@include breakpoint_480 {
		padding: 0px;
	}

	.comment-reply-title {
		font-size: 24px;
		margin-bottom: 20px;
		font-weight: 700;
	}

	.form-submit {
		.site-button {
			position: relative;
		}
	}
}

/*Comment list*/
.comments-area {
	padding: 0;

	.comments-title {
		font-size: 24px;
		margin-bottom: 20px;
	}

	.comment-form {
		padding: 30px;
		background-color: $clr-sky-blue-light;

		@include breakpoint_480 {
			margin: 0 -5px;
		}

		.comment-form-author,
		.comment-form-email,
		.comment-form-comment {
			label {
				display: none;
				line-height: 18px;
				margin-bottom: 10px;
			}

			input[type="text"] {
				width: 100%;
				height: 60px;
				padding: 20px;
				border: 1px solid $clr-gray;

				&.form-control:focus {
					box-shadow: none;
				}
			}
		}

		.comment-form-comment {
			textarea {
				width: 100%;
				padding: 20px;
				height: 120px;
				resize: none;
				border: 1px solid $clr-gray;

				&.form-control {
					&:focus {
						box-shadow: none;
					}
				}
			}
		}

	}
}

ol.comment-list {
	li.comment {
		.comment-respond {
			.comment-form {
				p {
					padding: 0px 15px !important;
				}
			}
		}
	}
}

.comment-form {
	.form-control {
		&:-webkit-input-placeholder {
			color: #777;
			font-size: 14px;
		}
	}
}

/*Single Blog Post*/
.blog-post-single-outer {
	.blog-post-single {
		.wt-post-meta-list {
			margin-bottom: 5px;
			width: 100%;
			clear: both;
			display: inline-block;

			.wt-list-content {
				float: left;
				margin-right: 30px;
				position: relative;
				color: #6e6e6e;
				font-size: 16px;

				&::after {
					content: '/';
					position: absolute;
					right: -20px;
					top: 0px;
				}

				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}

		.wt-post-info {
			padding-top: 0px;
		}

		.post-title {
			font-size: 36px;
			margin-bottom: 15px;

			@include breakpoint-991 {
				font-size: 30px;
			}
		}

		.wt-post-media {
			img {
				width: 100%;
			}

			.wt-post-meta {
				position: absolute;
				left: 0px;
				bottom: 0px;

				ul {
					background-color: $clr-primary;
					display: inline-block;
					margin-bottom: 0px;

					li {
						color: $clr-white;
						display: inline-table;
						font-size: 14px;
						text-align: center;
						padding: 8px 10px;
						font-weight: 500;
						position: relative;

						&:after {
							position: absolute;
							content: '|';
							right: -2px;
							top: 50%;
							transform: translateY(-50%);
						}

						a {
							color: $clr-white;
						}

						&:last-child {
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}

		blockquote {
			background: $clr-sky-blue-light;
			margin: 30px 0px;
			padding: 30px 30px 30px 100px;
			position: relative;
			overflow: hidden;
			font-family: $common-font;

			p {
				font-size: 20px;
				font-weight: 400;
				color: $clr-dark;
				margin-bottom: 0px;

				@include breakpoint_767 {
					font-size: 18px;
				}
			}

			img {
				opacity: 0.2;
				position: absolute;
				left: 30px;
				top: 24px;
				width: 160px;

			}

			@include breakpoint_420 {
				padding: 30px;

				img {
					position: inherit;
					top: auto;
					left: auto;
				}
			}

		}
	}


	.post-social-icons-wrap {
		float: right;

		@include breakpoint-991 {
			float: none;
			margin-top: 30px;
		}
	}

}

.post-area-tags-wrap {
	border-top: 1px solid #dedede;
	margin-top: 30px;
	padding-top: 30px;
}

.post-social-icons {
	margin: 0px;

	li {
		display: inline-block;

		a {
			height: 34px;
			width: 34px;
			background-color: $clr-sky-blue-light;
			line-height: 34px;
			padding: 0px;
			color: $clr-dark;
			font-size: 18px;
			text-align: center;
			margin-right: 3px;

			&:hover {
				color: $clr-white;
				background-color: #051721;
			}
		}
	}
}

/*Single Post Navigation*/
.post-navigation {
	margin-bottom: 36px;

	.post-nav-links {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		padding: 30px 0px;
		border-top: 1px solid #dedede;
		border-bottom: 1px solid #dedede;
		position: relative;

		&:after {
			content: '';
			width: 1px;
			height: 100%;
			background-color: #dedede;
			position: absolute;
			left: 50%;
			top: 0px;
		}

		.post-nav-item {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			width: 50%;

			@include breakpoint_640 {
				display: block;
			}

			.nav-post-arrow {
				margin-right: 20px;

				@include breakpoint_640 {
					margin-bottom: 10px;
				}

				i {
					font-size: 50px;
					line-height: 0.70;
					color: $clr-primary;
				}

			}

			.nav-post-meta {
				padding-right: 50px;

				@include breakpoint-480 {
					padding-right: 10px;
				}

				label {
					color: #616161;
					font-size: 14px;
					text-transform: uppercase;
					display: block;
					margin-bottom: 3px;
				}

				a {
					font-size: 18px;
					line-height: 24px;
					color: $clr-dark;
					font-weight: 500;
					font-family: $heading-font;

					@include breakpoint_640 {
						font-size: 16px;
						line-height: 16px;
						font-weight: 600;
					}
				}
			}

			&.nav-post-next {
				flex-direction: row-reverse;
				text-align: right;

				.nav-post-meta {
					padding-left: 50px;
					padding-right: 0px;

					@include breakpoint-480 {
						padding-left: 10px;
					}
				}

				.nav-post-arrow {
					margin-right: 0;
					margin-left: 20px;

				}
			}
		}
	}
}

.section-full {

	&.p-t120,
	&.p-b90,
	&.p-tb120 {
		@include breakpoint_991 {
			padding-top: 38px;
			padding-bottom: 10px;
		}
	}
}

/*Loading*/
.loading-area {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	overflow: hidden;

	.loading-box {
		width: 100%;
		height: 100%;
		background-color: $clr-white;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 1;
		z-index: 9999;
	}

	.loading-pic {
		width: 100%;
		position: absolute;
		top: 50%;
		z-index: 99999;
		text-align: center;
		transform: translateY(-50%);
	}
}

/*Loading Animation Start*/
#outer-barG {
	height: 31px;
	width: 109px;
	border: 2px solid rgb(30, 143, 208);
	overflow: hidden;
	background-color: rgb(255, 255, 255);
	margin: auto;

	.bar-lineG {
		background-color: rgb(255, 136, 0);
		float: left;
		width: 15px;
		height: 102px;
		margin-right: 20px;
		margin-top: -24px;
		transform: rotate(45deg);
	}

	.bar-animationG {
		margin-left: 143px;
		width: 143px;
		animation-name: bar-animationG;
		animation-duration: 2.24s;
		animation-iteration-count: infinite;
		animation-direction: normal;
	}
}


@keyframes bar-animationG {
	0% {
		margin-left: 132px;
		margin-top: -15px;
	}

	100% {
		margin-left: -109px;
		margin-top: -15px;
	}
}

/*Description list*/
.description-list {
	margin: 30px 0px;

	li {
		padding-left: 25px;
		list-style: none;
		position: relative;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0px;
		}

		i {
			color: $clr-primary;
			line-height: 26px;
			position: absolute;
			left: 0px;
			top: 0px;
			text-align: center;
			font-size: 22px;
		}
	}
}

/*Section Overlay*/
.overlay-wraper {
	position: relative;

	.overlay-main {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.opacity-01 {
	opacity: 0.1;
}

.opacity-02 {
	opacity: 0.2;
}

.opacity-03 {
	opacity: 0.3;
}

.opacity-04 {
	opacity: 0.4;
}

.opacity-05 {
	opacity: 0.5;
}

.opacity-06 {
	opacity: 0.6;
}

.opacity-07 {
	opacity: 0.7;
}

.opacity-08 {
	opacity: 0.8;
}

.opacity-09 {
	opacity: 0.9;
}

.overlay-wraper {
	>.container {
		position: relative;
		z-index: 1;
	}

	>.container-fluid {
		position: relative;
		z-index: 1;
	}
}

/*What We do*/
.tw-what-wedo-media {
	@include breakpoint-991 {
		text-align: center;
		margin-bottom: 30px;
	}
}

.tw-service-icon-box-wrap {
	position: relative;
	padding: 30px 90px 30px 40px;
	margin-top: 50px;
	z-index: 1;
	margin-bottom: 80px;

	@include breakpoint-991 {
		padding-left: 10px;
	}

	@include breakpoint-767 {
		margin-bottom: 50px;
	}

	@include breakpoint-480 {
		padding: 0px 30px 30px 10px;
		margin-bottom: 30px;
	}

	&:after {
		position: absolute;
		content: '';
		top: -50px;
		bottom: -50px;
		right: 0px;
		border-style: solid;
		border-color: $clr-primary;
		border-width: 60px 60px 60px 0px;
		left: 25%;
		z-index: -1;

		@include breakpoint-480 {
			border-width: 30px 30px 30px 0px;
			bottom: 0px;
			top: -30px;
		}
	}
}

.service-icon-box-two {
	position: relative;
	margin-bottom: 5px;
	padding: 20px;
	background-color: $clr-white;
	transition: all 0.3s linear;
	z-index: 0;

	&:before {
		width: 8px;
		content: '';
		position: absolute;
		z-index: 1;
		opacity: 0.5;
		left: -8px;
		top: 30px;
		bottom: 30px;
		background-color: $clr-primary;
		transition: all 0.3s linear;
	}


	&:hover {
		box-shadow: 0px 0px 50px rgba(30, 143, 208, 0.5);
		z-index: 999;

		&:before {
			left: 0%;
			opacity: 1;
		}
	}

	.service-icon-box-two-media {
		margin-right: 30px;
		margin-top: 20px;
		float: left;
		width: 70px;

		@include breakpoint-400 {
			margin-right: 20px;
			width: 40px;
		}
	}

	.service-icon-box-title {
		overflow: hidden;

		.wt-title {
			margin-bottom: 15px;

			span {
				font-size: 32px;
				padding-right: 5px;
			}

			@include breakpoint_767 {
				padding-right: 0px;
			}
		}

		p {
			margin-bottom: 0px;
		}
	}

	&.site-bg-black {
		.service-icon-box-title {
			.wt-title {
				a {
					color: $clr-white;
				}
			}
		}
	}
}

/*Blog post 4*/
.blog-post-4-outer {
	.wt-post-media {
		img {
			width: 100%;
		}
	}

	.wt-post-info {
		padding-top: 50px;
		position: relative;
		z-index: 1;

		.wt-post-meta {
			position: relative;

			ul {
				list-style: none;
				margin-bottom: 0px;

				li {
					position: relative;
					z-index: 1;

					&.post-date {
						background-color: $clr-dark;
						color: $clr-white;
						display: inline-table;
						font-size: 22px;
						line-height: 28px;
						text-align: center;
						padding: 20px 15px;
						font-weight: 600;
						position: absolute;
						right: 30px;
						bottom: 20px;
						width: 90px;
						font-family: $heading-font;

						&:before {
							position: absolute;
							content: '';
							border-left: 8px solid $clr-primary;
							border-bottom: 8px solid $clr-primary;
							top: 8px;
							left: -8px;
							bottom: -8px;
							width: 100%;
						}

						span {
							font-size: 40px;
							color: $clr-primary;
							font-weight: 700;
							display: inline-table;
							line-height: 0.75;
						}
					}

					&.post-category {
						font-size: 14px;
						font-weight: 600;
						color: $clr-primary;
					}
				}
			}
		}

		.wt-post-meta-list {
			text-align: left;
			font-size: 15px;
			line-height: 21px;
			margin-bottom: 20px;
			font-weight: 600;
			color: $clr-black;
			border-bottom: 1px solid #eee;
			padding-bottom: 15px;

			.wt-list-content {
				display: inline-block;

				a {
					padding: 0px 3px;

					&:hover {
						color: $clr-primary;
					}
				}
			}
		}

		.wt-post-title {
			.post-title {
				margin-bottom: 15px;
			}
		}
	}
}

.blog-no-image {
	background-color: $clr-primary;
	padding: 30px;
	color: $clr-white;
	position: relative;
	z-index: 1;

	.wt-post-info {
		display: flex;
		margin-bottom: 15px;

		.post-date {
			color: $clr-dark;
			display: inline-table;
			padding-top: 4px;
			font-size: 22px;
			line-height: 28px;
			text-align: center;
			padding-right: 20px;
			font-weight: 600;
			width: 90px;
			font-family: $heading-font;

			span {
				font-size: 40px;
				font-weight: 700;
				line-height: 0.75;
			}
		}

		.post-title {
			margin-bottom: 0px;

			a {
				color: $clr-white;
			}
		}
	}

	.qt-light {
		position: absolute;
		right: 20px;
		bottom: 20px;
		z-index: -1;
		opacity: 0.1;
	}
}

/*-----All Services------*/
.all_services {
	border: 1px solid #ced4da;
	padding: 30px;

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		li {
			position: relative;
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0px;
			}

			a {
				transition: $transition;
				position: relative;
				padding-left: 35px;
				display: block;
				color: $clr-dark;
				font-family: $heading-font;
				font-size: 18px;

				&:after {
					content: "\f04b";
					position: absolute;
					left: 0px;
					top: 8px;
					font-size: 10px;
					font-weight: 700;
					font-family: 'FontAwesome';
				}

				&:hover {
					color: $clr-primary;
				}

				&.active {
					color: $clr-primary;
				}
			}

		}
	}
}

.service-full-info {
	.service-category-title {
		margin-bottom: 30px;
		display: flex;
		align-items: center;

		.service-category-media {
			width: 100px;
			display: inline-block;
		}

		h2 {
			display: inline-block;

			@include breakpoint-991 {
				font-size: 30px;
			}
		}
	}

	.wt-title {
		font-size: 36px;

		@include breakpoint-991 {
			font-size: 28px;
		}
	}
}

.service-offered {
	display: flex;

	.media {
		flex: 1.5;
	}

	.service-offered-list {
		flex: 1;
	}

	@include breakpoint-1199 {
		display: block;

		.media {
			margin-bottom: 30px;
		}
	}
}

.tw-checked-list {
	ul {
		list-style: none;
		margin-bottom: 0px;

		li {
			position: relative;
			font-size: 16px;
			margin-bottom: 3px;
			padding-left: 20px;

			&:after {
				content: '\f105';
				font-family: 'FontAwesome';
				color: $clr-primary;
				left: 0px;
				top: 0px;
				position: absolute;
			}
		}
	}
}

#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: $clr-white;
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;

	&.open {
		transform: translate(0px, 0px) scale(1, 1);
		opacity: 1;
		z-index: 999;
		display: block;
	}

	form {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 500px;
		width: 100%;
		color: $clr-white;
		border: 1px solid $clr-primary;
		font-size: 30px;
		font-weight: 300;
		text-align: left;
		outline: none;
		padding: 10px;
		border-radius: 0px;

		span {
			display: block;
		}
	}

	input[type="search"] {
		background: none;
		border: none;
		padding: 0px 12px;
		outline: none;
		color: $clr-dark;
		font-size: 24px;
		text-align: center;

		@include breakpoint_575 {
			font-size: 14px;
		}

		&:focus {
			box-shadow: none;
		}

		&::placeholder {
			color: $clr-dark;
		}
	}


	.search-btn {
		border: none;
		background: none;
		padding: 0px 30px;
		outline: none;
		display: block;
		background-color: $clr-primary;
		width: 100%;
		text-align: center;

		i {
			font-size: 36px;
			color: $clr-white;
		}
	}

	.close {
		position: fixed;
		top: 30px;
		right: 30px;
		opacity: 1;
		font-size: 27px;
		color: $clr-white;
		width: 60px;
		height: 60px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
		background-color: $clr-primary;

		&:after,
		&:before {
			content: "";
			position: absolute;
			width: 2px;
			height: 32px;
			background-color: $clr-white;
			right: 28px;
			top: 16px;
		}

		&:after {
			transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:hover:after,
		&:hover:before {
			cursor: pointer;
		}
	}

}

/*services-box-one*/
.tw-service-gallery-style1-area {
	position: relative;
}

.services-gallery-block-outer {
	@include breakpoint_1440 {
		max-width: calc(100% - 170px);
	}

	@include breakpoint_1200 {
		max-width: 100%;
	}

	@include breakpoint-991 {
		padding: 38px 15px;
		max-width: 720px;
		margin: 0px auto;
	}
}

.tyre-mark-bg {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		left: 0px;
		bottom: 0px;
		height: 100%;
		width: 100%;
		z-index: -1;
		background-image: url(../public/assets/images/tyre-mark.png);
		background-size: auto;
		background-repeat: no-repeat;
		background-position: left bottom;
	}
}

/*Hilite Text*/
.tw-hilite-text-wrap {
	position: relative;

	.tw-hilite-text {
		position: absolute;

		&.right {
			bottom: -80px;
			right: 20px;
		}

		span {
			display: inline-table;
			font-family: $heading-font;
			font-size: 120px;
			line-height: 0.75;
			font-weight: 700;
			color: $clr-black;
			text-transform: uppercase;
			opacity: 0.05;

			@include breakpoint_991 {
				display: none;
			}
		}
	}
}

.tw-hilite-text-wrap2 {
	position: relative;

	.tw-hilite-text {
		span {
			display: inline-table;
			font-family: $heading-font;
			font-size: 120px;
			line-height: 0.75;
			font-weight: 700;
			color: $clr-black;
			text-transform: uppercase;
			opacity: 0.05;

			@include breakpoint-991 {
				display: none;
			}
		}
	}
}

.services-gallery-style1 {
	margin-bottom: 30px;

	@include breakpoint_1200 {
		padding-left: 0px;
	}

	@include breakpoint_991 {
		margin-bottom: 10px;
	}

	.owl-carousel .owl-stage-outer {
		position: relative;
		margin-bottom: 30px;
	}



	.services-gallery-one.owl-btn-bottom-left {
		.owl-nav {
			.owl-next {
				right: -1px;
				transition: all 0.2s linear;
			}

			.owl-prev {
				transition: all 0.2s linear;
			}
		}
	}

	.services-gallery-one {
		display: flex;
		&.owl-btn-bottom-left {
			.owl-nav {
				opacity: 1;
				position: absolute;
				left: 0px;
				bottom: 0px;

				@include breakpoint_991 {
					text-align: center;
					position: inherit;
					margin-top: 20px;
					bottom: 20px;

					.owl-prev {
						margin-right: 14px;
					}

					.owl-next {
						margin-right: 0px;
					}
				}
			}
		}

		// @include breakpoint_991 {
		// 	flex-direction: column;
		// }
	}
}

.service-box-style1 {
	position: relative;
	// overflow: hidden;
	cursor: pointer;

	.service-content {
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		position: absolute;
		width: 100%;
		z-index: 1;
		transition: all 0.8s linear;

		.service-content-inner {
			padding: 20px;
			margin: 20px;
			transition: all 0.8s linear;
			background-color: rgba(11, 47, 68, 0.9);
			position: relative;
			display: table;
			height: 0%;

			.service-content-top {
				display: table-header-group;

				.service-title-large {
					text-align: right;

					a {
						color: $clr-white;
						transition: all 0.8s linear;
						text-transform: uppercase;
						font-size: 32px;
						line-height: 0.7;
						font-weight: 600;
						font-family: $heading-font;

						@include breakpoint-991 {
							font-size: 26px;
						}
					}
				}
			}

			.service-content-bottom {
				display: table-cell;
				vertical-align: bottom;

				.service-title-large-number {
					font-size: 40px;
					color: $clr-white;
					transition: all 0.8s linear;
					font-weight: 600;
					font-family: $heading-font;

					@include breakpoint-991 {
						font-size: 30px;
					}
				}

				p {
					color: $clr-white;
					transition: all 0.8s linear;
					margin-bottom: 20px;
				}

				.site-button-2 {
					transition: all 0.8s linear;
				}
			}
		}
	}

	&:hover {
		.service-content {
			bottom: 40px;
			height: calc(100% - 40px);

			.service-content-inner {
				height: 100%;
				background-color: rgba(255, 138, 0, 0.9);

				.service-content-top {
					.service-title-large {
						a {
							color: $clr-dark;
						}
					}
				}

				.service-content-bottom {
					.service-title-large-number {
						color: $clr-dark;
					}

					p {
						color: $clr-dark;
					}

					.site-button-2 {
						color: $clr-dark;
					}
				}
			}
		}
	}

	.service-media {
		img {
			width: 100%;

			@include breakpoint-991 {
				height: 420px;
				object-fit: cover;
			}
		}
	}

}

/*site-button-verticle*/

.site-button-2 {
	display: inline-block;
	color: $clr-white;
	font-size: 18px;
	text-transform: uppercase;
	transition: $transition;
	position: relative;
	overflow: hidden;

	&:after {
		content: '\f105';
		position: absolute;
		font-family: 'FontAwesome';
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0px;
		transition: $transition;
		opacity: 0;
	}

	&:hover {
		padding-left: 20px;
		color: $clr-primary;

		&:after {
			font-size: 24px;
			left: 0px;
			opacity: 1;
		}
	}
}

.tw-project-1-wrap {
	&.tw-project-1-wrap-bg {
		position: relative;
		z-index: 1;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			left: 0px;
			top: 0px;
			height: 100%;
			width: 100%;
			z-index: -1;
			background-image: url(../public/assets/images/project/project-bg.jpg);
			background-size: auto;
			background-repeat: no-repeat;
			background-position: left top;
		}
	}

	.tw-project-1-content {
		position: relative;
		margin-left: 30%;
		margin-bottom: 120px;
		padding-top: 90px;

		@include breakpoint-1366 {
			margin-left: 24%;
		}

		@include breakpoint-991 {
			margin-left: 0px;
		}

		.tw-project-1-content-position {
			max-width: 1038px;
			top: 45px;
			padding: 65px 48px;
			box-shadow: 0px 0px 50px rgba(30, 143, 208, 0.5);
			position: relative;
			z-index: 1;

			@include breakpoint-1366 {
				margin-right: 50px;
			}

			@include breakpoint-991 {
				margin-right: 0px;
			}

			@include breakpoint-420 {
				padding: 20px;
			}

			&:before {
				content: '';
				position: absolute;
				left: 48px;
				top: -75px;
				right: -75px;
				bottom: -75px;
				border: 20px solid $clr-sky-blue;
				z-index: -1;

				@include breakpoint-1366 {
					right: -50px;
				}

				@include breakpoint-991 {
					display: none;
				}
			}

			&:after {
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				right: 0px;
				bottom: 0px;
				background-color: $clr-white;
				z-index: -1;
			}
		}
	}
}

.project-carousel {
	.owl-stage-outer {
		padding-bottom: 0px;
	}
}

.project-new-2 {
	position: relative;
	margin-bottom: 50px;

	.wt-img-effect {
		position: relative;

		img {
			width: 100%;
		}
	}

	.project-new-content {
		padding: 22px 30px 30px 30px;
		position: absolute;
		background-color: $clr-white;
		left: 30px;
		bottom: -50px;
		right: 0px;

		span {
			display: inline-block;
			color: $clr-primary;
			font-size: 14px;
			font-weight: 400;
		}

		.wt-title {
			margin-bottom: 0px;

			a {
				transition: $transition;

				&:hover {
					color: $clr-primary;
				}
			}
		}

		.site-button-h-align {
			position: absolute;
			font-size: 12px;
			color: $clr-dark;
			text-transform: uppercase;
			font-weight: 600;
			transform: rotate(-90deg) translateX(-50%);
			left: 14px;
			top: 50%;
			transform-origin: 0;
			margin-top: -15px;
			transition: $transition;

			&:before {
				content: '';
				position: absolute;
				right: 100%;
				top: 50%;
				width: 15px;
				height: 1px;
				background-color: $clr-primary;
				margin-right: 4px;
			}

			&:hover {
				color: $clr-primary;
			}
		}
	}

	.project-view {
		position: absolute;
		top: 30px;
		left: 30px;
		opacity: 1;
		transform: scaleY(0);
		transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s 0.5s;

		.project-view-btn {
			position: relative;
			display: block;
			width: 60px;
			height: 60px;

			i {
				width: 60px;
				height: 60px;
				font-size: 28px;
				line-height: 60px;
				display: block;
				text-align: center;
				background-color: $clr-primary;
				position: relative;
				color: $clr-white;
			}
		}

	}

	&:hover {
		.project-view {
			opacity: 1;
			z-index: 3;
			transform: scaleY(1);
		}

		.wt-img-effect {

			&:after,
			&:before {
				width: 100%;
			}
		}
	}

}

/*touchspin input type number*/
.bootstrap-touchspin {
	.input-group-btn-vertical {
		position: relative;
		white-space: nowrap;
		width: 1%;
		vertical-align: middle;
		display: table-cell;

		>.btn {
			display: block;
			float: none;
			width: 100%;
			max-width: 100%;
			padding: 10px;
			margin-left: -1px;
			position: relative;
			border-width: 1px;
			border-style: solid;
			background: $clr-primary;
		}

		.bootstrap-touchspin-up {
			border-radius: 0;
			border-top-right-radius: 0;
		}

		.bootstrap-touchspin-down {
			margin-top: -2px;
			border-radius: 0;
			border-bottom-right-radius: 0;
		}

		i {
			position: absolute;
			top: 7px;
			left: 7px;
			font-size: 9px;
			font-weight: normal;
			color: $clr-white;
		}
	}
}

.tw-contact-bg-section {
	.tw-contact-bg-inner {
		padding: 50px 40px;
		margin-bottom: 30px;
		position: relative;
		z-index: 1;
		background-size: cover;
		background-position: center center;

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			left: 0px;
			top: 0px;
			position: absolute;
			z-index: -1;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, rgba(255, 138, 0, 0.65) 100%);
			/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6ff8a00', GradientType=0);
			/* IE6-9 */
		}

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			left: 0px;
			top: 0px;
			position: absolute;
			background-color: rgba(0, 0, 0, 0.8);
			z-index: -1;
		}

		.section-top {
			margin-bottom: 50px;
			text-align: center;

			span {
				display: block;
				color: $clr-white;
				font-size: 18px;
				font-family: $heading-font;
				position: relative;
				margin-bottom: 20px;
				padding-bottom: 10px;

				&:after {
					position: absolute;
					background: $clr-sky-blue;
					width: 60px;
					height: 5px;
					content: '';
					left: 50%;
					bottom: -5px;
					transform: translateX(-50%);
				}
			}

			.tw-con-number {
				font-size: 36px;

				a {
					color: $clr-white;
				}

				@include breakpoint-1199 {
					font-size: 26px;
				}

				@include breakpoint-420 {
					font-size: 26px;
				}
			}
		}

		.section-bot {
			ul {
				margin: 0px;
				list-style: none;

				li {
					color: $clr-white;
					text-align: center;
					margin-bottom: 20px;

					span {
						width: 46px;
						height: 46px;
						line-height: 46px;
						background-color: $clr-white;
						display: block;
						border-radius: 50%;
						text-align: center;
						margin: 0px auto 8px;
					}

					p {
						margin-bottom: 0px;
					}

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}


}

/*Google map*/
.google-map {
	width: 100%;
	height: 460px;

	iframe {
		width: 100%;
		border: 0px;
		filter: grayscale(100%);
	}
}

/*Text with bg image*/
.tw-company-years {
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	@include breakpoint-575 {
		display: block;
	}

	.light {
		position: relative;
		margin-right: 60px;
		display: inline-flex;

		h1 {
			font-size: 200px;
			line-height: 0.75;
			display: inline-table;
			color: $clr-dark;
			margin: 0px;
		}

		@include breakpoint-991 {
			margin-right: 20px;

			h1 {
				font-size: 100px;
			}
		}
	}

	img {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		mix-blend-mode: lighten;
		transform: translateY(-50%);
	}

	.tw-company-info {
		max-width: 500px;

		span {
			font-size: 36px;
			line-height: 36px;
			font-weight: 600;
			font-family: $heading-font;
			color: $clr-dark;

			@include breakpoint_991 {
				font-size: 30px;
			}

			@include breakpoint-575 {
				font-size: 26px;
			}
		}
	}
}

/* Half effect button */
.btn-half {
	cursor: pointer;
	background-color: $clr-primary;
	z-index: 0;
	display: inline-block;
	position: relative;
	overflow: visible;

	&:after {
		position: absolute;
		right: -6px;
		top: -6px;
		bottom: -6px;
		content: '';
		width: 20px;
		border: 2px solid $clr-primary;
		transition: $transition;
	}

	span {
		color: $clr-white;
		display: block;
		padding-left: 0%;
		padding-right: 35px;
		font-size: 18px;
		line-height: 24px;
		font-family: $common-font;
		font-weight: 400;
		transform: scaleX(0.9);
		transform-origin: center left;
		transition: color 0.3s ease;
		position: relative;
		z-index: 1;

		@include breakpoint-480 {
			font-size: 14px;
		}
	}

	em {
		position: absolute;
		height: 1px;
		background-color: $clr-white;
		width: 26px;
		right: 30px;
		top: 50%;
		transition: all 0.3s ease;
		z-index: 1;

		@include breakpoint-480 {
			right: 15px;
		}
	}

	&:hover {
		em {
			width: 40px;
		}

		&:after {
			width: 40px;
		}
	}
}

/*Why Choose Section*/
.tw-why-choose-area-top {
	background-position: bottom right;

	@include breakpoint-767 {
		background-size: auto;
	}
}

.tw-why-choose-area {
	padding-bottom: 100px;

	@include breakpoint-991 {
		padding-bottom: 40px;
	}
}

.tw-why-choose-section {
	.row {
		align-items: center;
		padding-bottom: 90px;

		@include breakpoint-991 {
			padding-bottom: 0px;
		}
	}

	.tw-why-choose-left {
		@include breakpoint-991 {
			margin-bottom: 50px;
		}

		@include breakpoint-480 {
			margin-bottom: 40px;
		}

		strong {
			font-size: 18px;
			line-height: 26px;
			font-weight: 400;
			margin-bottom: 20px;
			display: block;
		}

		.tw-why-choose-left-bottom {
			padding-top: 30px;
			display: flex;

			@include breakpoint-480 {
				display: block;
			}

			.site-button {
				margin-right: 30px;
			}

			.sign-font {
				position: relative;

				span {
					font-family: 'Sacramento', cursive;
					font-size: 45px;
					color: $clr-black;
					position: absolute;
					left: 0px;
					top: 0px;
					transform: rotate(-20deg);

					@include breakpoint-480 {
						position: relative;
					}
				}
			}
		}
	}

	.tw-why-choose-right {
		@include breakpoint-991 {
			margin-bottom: 30px;
		}

		position: relative;

		.tw-why-choose-media1 {
			position: relative;
			max-width: 338px;
			padding-top: 160px;

			@include breakpoint-480 {
				padding-top: 0px;
				max-width: 100%;
				margin-bottom: 40px;
			}

			img {
				width: 100%;
			}
		}

		.tw-why-choose-media2 {
			position: absolute;
			max-width: 338px;
			right: 0px;
			top: 0px;
			z-index: 2;

			@include breakpoint-480 {
				position: inherit;
				max-width: 100%;
				margin-left: 20px;

				img {
					width: 100%;
				}
			}

			&:after {
				position: absolute;
				content: '';
				border-left: 40px solid $clr-white;
				border-bottom: 40px solid $clr-white;
				left: -40px;
				bottom: -40px;
				height: 100%;
				width: 100%;
				z-index: -1;
				box-shadow: 0px 40px 60px rgba(30, 143, 208, 0.7);

				@include breakpoint-480 {
					border-left: 20px solid #fff;
					border-bottom: 20px solid #fff;
					left: -20px;
					bottom: -20px;
				}
			}
		}
	}
}

.tw-any-help-section {
	.container {
		position: relative;

		.tw-any-help-inner {
			background-color: $clr-white;
			padding: 30px;
			position: absolute;
			z-index: 2;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
			box-shadow: 0px 20px 40px rgba(255, 138, 0, 0.4);

			img {
				position: absolute;
				left: -150px;
				top: 50%;
				transform: translateY(-50%);

				@include breakpoint-991 {
					position: inherit;
					left: auto;
					top: auto;
					transform: none;
					margin-bottom: 20px;
				}
			}

			span {
				display: block;

				&.tw-24 {
					text-transform: uppercase;
					color: $clr-dark;
				}

				&.tw-help-title {
					font-size: 36px;
					font-weight: 700;
					color: $clr-primary;
					font-family: $heading-font;
				}

				&.tw-help-number {
					font-size: 32px;
					font-weight: 500;
					color: $clr-dark;
				}

				@include breakpoint-991 {
					&.tw-help-number {
						font-size: 20px;
					}

					&.tw-help-title {
						font-size: 26px;
					}
				}
			}

			@include breakpoint-991 {
				position: inherit;
				transform: none;
				margin-top: 60px;
			}
		}
	}
}

/*Estimation Section*/
.tw-estimation-area {
	@include breakpoint-991 {
		padding-bottom: 0px !important;
	}
}

.tw-estimation-section {
	.row {
		justify-content: center;
	}
}

.tw-est-section-block {
	height: 700px;
	position: relative;
	z-index: 1;
	border: 15px solid transparent;
	transition: $transition;

	@include breakpoint-991 {
		height: auto;
	}

	.tw-est-section-block-content {
		position: absolute;
		left: 0px;
		bottom: 0px;
		color: $clr-white;
		padding: 10px;
		transform: translate3d(0%, 0, 0);
		transition: opacity 0.35s, transform 0.8s;

		@include breakpoint-991 {
			position: relative;
		}

		.tw-est-section-number {
			font-size: 80px;
			font-family: $heading-font;
			font-weight: 700;

			@include breakpoint-991 {
				font-size: 40px;
			}
		}

		.tw-title {
			color: $clr-white;
		}
	}

	&:hover {
		border: 15px solid rgba(255, 255, 255, 0.5);
		background-color: rgba(0, 0, 0, 0.4);

		.tw-est-section-block-content {
			transform: translate3d(0%, -100px, 0);

			@include breakpoint-991 {
				transform: translate3d(0%, -20px, 0);
			}
		}
	}
}

.site-button-2-outline {
	border: 2px solid $clr-white;
	padding: 10px 16px;
	display: inline-block;

	i {
		color: $clr-white;
		font-size: 24px;
		line-height: 24px;
		display: block;
		position: relative;
		padding-left: 18px;

		&:after {
			content: '';
			position: absolute;
			width: 25px;
			height: 2px;
			background-color: $clr-white;
			right: 2px;
			top: 50%;
			transform: translateY(-50%);
			margin-top: 1px;
		}
	}

	&.dark {
		border: 2px solid $clr-dark;

		i {
			color: $clr-dark;

			&:after {
				background-color: $clr-dark;
			}
		}
	}
}

/*Testimonials 1*/

.slider-vertical-wrap {
	position: relative;
	margin-left: 20px;
	padding: 170px 0px;
	max-width: 540px;
	z-index: 1;

	@include breakpoint_1199 {
		max-width: calc(100% - 80px);
		margin-left: 0px;
	}

	@include breakpoint_480 {
		max-width: calc(100%);
		padding: 40px 0px;
	}

	.slick-prev,
	.slick-next {
		top: auto;
		bottom: -60px;
		width: 30px;
		height: 30px;

		@include breakpoint-480 {
			bottom: -45px;
		}

		&:before {
			color: $clr-white;
			font-family: 'FontAwesome';
			background-color: $clr-dark;
			opacity: 1;
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: block;
		}

	}

	.slick-prev {
		left: 4px;

		&:before {
			content: "\f106";

			@include breakpoint-480 {
				content: "\f104";
			}
		}
	}

	.slick-next {
		left: 56px;

		&:before {
			content: "\f107";

			@include breakpoint-480 {
				content: "\f105";
			}
		}
	}

}

.tw-testimonial-1-area {
	overflow: hidden;
}

.tw-testimonials1-cell {
	text-align: left;
	padding-left: 130px;

	@include breakpoint_1270 {
		padding-right: 30px;
	}

	@include breakpoint_480 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.tw-testimonials1-text {
		margin-bottom: 40px;
		position: relative;
		font-size: 14px;
		font-style: italic;

		@include breakpoint_480 {
			margin-bottom: 15px;
		}

		.tw-testimonials1-quote {
			display: block;
			margin-bottom: 30px;

			@include breakpoint_480 {
				margin-bottom: 15px;
			}
		}
	}

	.tw-testimonials1-name {
		font-size: 30px;
		line-height: 38px;
		font-family: 'Sacramento', cursive;
		color: $clr-dark;
		font-weight: 600;
	}

	.tw-testimonials1-postion {
		font-size: 14px;
		color: $clr-primary;
		position: relative;
		padding-left: 30px;

		&:before {
			content: '';
			position: absolute;
			left: 0px;
			top: 50%;
			width: 20px;
			height: 1px;
			background-color: $clr-dark;
			transform: translateY(-50%);
		}
	}
}

.slick-testimonials-thumbnails {
	width: 105px;
	position: absolute;
	left: 0px;
	top: 50%;
	z-index: 1;
	transform: translateY(-50%);

	@include breakpoint-480 {
		width: 100%;
		position: inherit;
		top: auto;
		transform: none;
		left: auto;
	}

	.slick-list {
		padding: 0px !important;
	}

	.slick-arrow {
		display: none !important;
	}

	.slick-active.slick-center {
		.slick-testimonials-thumb {
			&:before {
				opacity: 1;
			}

			@include breakpoint-480 {
				img {
					border-color: $clr-primary;
				}

				&:before {
					display: none;
				}
			}
		}
	}
}

.slick-testimonials-thumb {
	cursor: pointer;
	margin-bottom: 15px;
	padding-right: 15px;
	transition: $transition;
	position: relative;
	z-index: 1;

	@include breakpoint-480 {
		padding: 0px
	}

	&:before {
		content: '';
		width: 50px;
		height: 50px;
		position: absolute;
		right: 4px;
		top: -10px;
		background-color: $clr-primary;
		z-index: -1;
		opacity: 0;

		@include breakpoint-480 {
			.slick-testimonials-thumb:before {
				width: 35px;
				height: 35px;
				right: 10px;
				top: -5px;
			}
		}
	}

	img {
		width: 100%;
		border: 3px solid #fff;
	}
}

.tw-testimonial-border-outer {
	position: absolute;
	width: 100%;

	@include breakpoint_1199 {
		position: relative;
	}

	@include breakpoint_991 {
		margin-bottom: 30px;
	}
}

.tw-testimonial-inner-wrap {
	position: relative;
	z-index: 1;
}

.tw-testimonial-border {
	position: absolute;
	content: '';
	top: 0px;
	left: -12px;
	bottom: 0px;
	width: 50%;
	border-width: 72px 72px 72px 0px;
	border-style: solid;
	border-color: #1e8fd0;

	@include breakpoint_1199 {
		left: auto;
		right: 0px;
	}

	@include breakpoint_480 {
		display: none;
	}

	&:after {
		position: absolute;
		content: '';
		top: -72px;
		left: -60px;
		width: 60px;
		height: 72px;
		background-color: #1e8fd0;

		@include breakpoint_480 {
			display: none;
		}
	}
}

.tw-client-logo-wrap {
	padding: 90px 0px 0px 0px;
	margin-top: 72px;

	@include breakpoint_1199 {
		padding: 0px;
		margin-top: 0px;
		margin-bottom: 30px;
	}
}

.tw-client-logo-bg {
	background-color: #f2f2f2;
	position: absolute;
	content: '';
	top: 72px;
	bottom: 200px;
	left: 0px;
	width: 50%;
	z-index: -1;

	@include breakpoint_1199 {
		display: none;
	}
}

.tw-client-logo-inner {
	position: relative;
	padding-right: 30px;

	@include breakpoint-991 {
		padding-right: 0px;
	}

	ul {
		list-style: none;
		margin-bottom: 30px;
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #9f9f9f;
		border-left: 1px solid #9f9f9f;

		li {
			width: 33.33%;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #9f9f9f;
			border-right: 1px solid #9f9f9f;

			.logo-media {
				padding: 30px 0px;
				width: 100%;
				text-align: center;

				img {
					width: 100px;
					filter: grayscale(100%);
					transition: $transition;
				}

				&:hover {
					img {
						filter: none;
					}
				}
			}
		}
	}
}

.truck-img {
	position: relative;
}

/*Booking Section*/
.tw-booking-area {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;

	.tw-booking-media {
		margin-right: -40px;

		@include breakpoint-991 {
			margin-right: 0px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
}

/*  bhoechie tab */
.tw-booking-section {
	padding-bottom: 30px;
}

.tw-booking-form {
	background-color: $clr-white;
	padding: 50px 30px;
	margin-left: 100px;
	min-height: 540px;
	box-shadow: 0px 0px 60px rgba(30, 143, 208, 0.7);

	@include breakpoint-991 {
		margin-left: 0px;
	}

	@include breakpoint-480 {
		padding: 20px;
	}

	.booking-tab-container {
		background-color: #ffffff;
		border-radius: 4px;

		.booking-tab-menu {
			padding-right: 0;
			padding-left: 0;
			padding-bottom: 0;
			margin-left: -60px;
			width: 170px;

			@include breakpoint_1199 {
				width: auto;
				margin-left: 30px;
			}

			@include breakpoint-480 {
				margin-left: 10px;
			}

			.list-group {
				margin-bottom: 0;

				@include breakpoint_1199 {
					display: block;
				}

				.list-group-item {
					border-radius: 0px;

					@include breakpoint_1199 {
						display: inline-block;
					}

					&+.list-group-item.active {
						margin-top: 0px;
					}
				}

				&>button {
					transition: $transition;
					margin-bottom: 30px;
					padding: 20px;
					background-color: $clr-white;
					border: 1px solid #ebebeb;

					@include breakpoint-480 {
						padding: 10px;
					}

					&:last-child {
						margin-bottom: 0px;
					}

					.media {
						margin-bottom: 10px;
						filter: grayscale(100%);

						@include breakpoint-480 {
							img {
								height: 40px;
							}
						}
					}

					span {
						display: block;
						color: #b7b8b8;
					}

					&.active {
						border: 1px solid transparent;
						box-shadow: 0px 0px 30px rgba(30, 143, 208, 0.5);

						.media {
							margin-bottom: 10px;
							filter: grayscale(0%);
						}

						span {
							display: block;
							color: $clr-dark;
						}
					}
				}
			}
		}

		.booking-tab-content {
			background-color: #ffffff;
			padding-left: 20px;
			padding-top: 10px;
			transition: $transition;

			&:not(.active) {
				display: none;
			}

			@include breakpoint-480 {
				padding: 0px;
			}
		}

	}

	.tw-booking-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.tw-booking-footer-text {
		display: inline-table;
		font-family: "Oswald", sans-serif;
		font-size: 120px;
		line-height: 0.75;
		font-weight: 700;
		color: #000;
		text-transform: uppercase;
		opacity: 0.05;

		@include breakpoint_1199 {
			font-size: 50px;
		}

		@include breakpoint_480 {
			display: none;
		}
	}

	.track-and-trace-form {
		textarea.form-control {
			min-height: 100px;
			resize: none;
		}
	}
}

.tw-inline-checked {
	display: flex;

	@include breakpoint_767 {
		display: block;
	}

	.form-check {
		margin-right: 30px;

		.form-check-input {
			border-radius: 0px;
		}
	}
}

.book-other-services {

	.form-control,
	.form-select {
		border: 0px;
	}

	@include breakpoint-420 {
		padding: 15px !important;
	}
}

.form-select {
	height: 50px;
	font-size: 13px;
	line-height: 20px;
	padding: 10px;
	border-radius: 0px;
	color: #6c757d;

	option {
		padding-left: 0px;
	}

	&:focus {
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
		background-color: #F8F8F8;
	}
}

/*tw-we-achived*/
.tw-we-achived {
	@include breakpoint-767 {
		margin: 30px 0px 0px 0px;
	}
}

.tw-we-achived-section {
	display: flex;

	.tw-we-achived-box-warp {
		width: 30%;
		display: flex;

		@include breakpoint-991 {
			display: block;
			width: 100%;
		}

		.tw-we-achived-box {
			padding: 60px 40px 60px 40px;
			display: inline-block;
			margin-left: auto;

			@include breakpoint-991 {
				display: block;
				padding: 30px;
			}

			.counter {
				color: $clr-primary;
				font-size: 60px;
				font-family: $common-font;

				@include breakpoint-991 {
					font-size: 30px;
				}

				span {
					color: $clr-primary;
					font-size: 60px;
					font-family: $common-font;
					display: block;
					padding-left: 0px;

					@include breakpoint-991 {
						font-size: 30px;
					}

					&:before {
						display: none;
					}
				}
			}

			span {
				display: block;
				color: $clr-white;
				font-size: 14px;
				text-transform: uppercase;
				font-family: $common-font;
				font-weight: 500;
				letter-spacing: 1px;
				padding-left: 70px;
				position: relative;

				&:before {
					position: absolute;
					content: '';
					width: 56px;
					height: 10px;
					left: 0px;
					top: 6px;
					background-color: $clr-white;
				}
			}
		}

		&.bg-skew {
			position: relative;
			z-index: 1;
			width: 40%;

			@include breakpoint-991 {
				width: 100%;
			}

			&:after {
				background-color: $clr-white;
				top: -1px;
				bottom: -1px;
				right: 0px;
				left: 0px;
				position: absolute;
				content: '';
				z-index: -1;
				transform: skewX(-20deg);

				@include breakpoint-768 {
					transform: none;
				}
			}

			span {
				color: $clr-dark;

				&:before {
					background-color: $clr-dark;
				}
			}
		}
	}

	@include breakpoint-991 {
		display: block;
	}
}

/*Estimation Section*/
.tw-estimation-2-section {
	.row {
		justify-content: center;
	}
}

.tw-estimation-2-area {
	position: relative;

	&:before {
		height: 440px;
		content: '';
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: $clr-gray-light;
		position: absolute;

		@include breakpoint-991 {
			display: none;
		}
	}
}

.tw-est-2-section-block {
	position: relative;
	z-index: 1;
	transition: $transition;
	text-align: center;
	margin-top: 62px;

	@include breakpoint-991 {
		margin-bottom: 30px;
	}

	@include breakpoint-767 {
		margin-top: 0px;
		border: 1px solid $clr-gray;
	}

	.tw-est-2-section-block-content {
		padding: 80px 30px;

		@include breakpoint-991 {
			padding: 80px 30px 30px 30px;
			text-align: center;
		}

		@include breakpoint-767 {
			padding: 30px;
		}

		.tw-est-2-section-number {
			font-size: 160px;
			color: #efefef;
			font-family: $heading-font;
			font-weight: 700;
			line-height: 0.7;
			display: inline-table;
			position: absolute;
			top: -62px;
			left: 30px;
			transition: $transition;

			@include breakpoint-991 {
				left: 50%;
				transform: translateX(-50%);
				font-size: 100px;
				top: -38px;
			}

			@include breakpoint-767 {
				position: inherit;
				left: auto;
				transform: inherit;
				font-size: 60px;
			}
		}

		.media {
			width: 170px;
			height: 170px;
			background-color: $clr-gray-light;
			border-radius: 50%;
			line-height: 170px;
			margin: 0px auto 30px;
		}

		.tw-title {
			color: $clr-dark;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 40px;
		}
	}

	&:hover,
	&.active {
		background-color: $clr-white;
		box-shadow: 0px 0px 60px rgba(30, 143, 208, 0.5);

		.tw-est-2-section-number {
			color: $clr-primary;
		}
	}
}

/*Services 3*/
.services-gallery-style3 {
	margin-bottom: 30px;

	.service-box-style3 {
		background-color: $clr-white;
		padding: 30px;
		margin: 20px;
		position: relative;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			left: -20px;
			top: -20px;
			bottom: -20px;
			right: -20px;
			border: 20px solid #f1f1f1;
			z-index: -1;
			transition: $transition;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0px;
			top: 0px;
			width: 10px;
			height: 76px;
			background-color: $clr-primary;
			transition: $transition;
		}

		.service-media {
			margin-bottom: 30px;
		}

		.service-content {
			.service-title-large {
				a {
					transition: all 0.8s linear;
					color: $clr-dark;
				}
			}

			.service-title-large-number {
				font-size: 40px;
				color: $clr-primary;
				margin-right: 20px;
				font-weight: 700;
			}

			p {
				transition: all 0.8s linear;
				margin-bottom: 20px;
			}

			.site-button-2 {
				color: $clr-primary;
				transition: $transition;
				position: relative;
				overflow: hidden;

				&:after {
					content: '\f105';
					position: absolute;
					font-family: 'FontAwesome';
					left: -20px;
					top: 50%;
					transform: translateY(-50%);
					font-size: 0px;
					transition: $transition;
					opacity: 0;
				}

				&:hover {
					padding-left: 20px;

					&:after {
						font-size: 24px;
						left: 0px;
						opacity: 1;
					}
				}
			}

		}

		.service-media {
			img {
				width: auto;
				height: 60px;
			}
		}

		&:hover {
			&:after {
				border: 20px solid $clr-primary;
			}

			&:before {
				opacity: 0;
			}
		}
	}

}

.tw-sidebar-gallery {
	margin-top: -20px;

	ul {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0px -10px;

		li {
			padding: 20px 10px 0px 10px;
			display: block;
			width: 33.33%;

			a {
				display: block;
				text-align: center;
				position: relative;
				background-color: $clr-black;

				img {
					transition: $transition;
				}

				i {
					opacity: 0;
					width: 46px;
					height: 46px;
					line-height: 46px;
					border-radius: 50%;
					background-color: $clr-white;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: $transition;
				}
			}

			&:hover {
				a {
					img {
						opacity: 0.3;
					}

					i {
						opacity: 1;
					}
				}
			}
		}
	}
}

/*Home 1 banner*/

.twm-slider1-wrap {
	background-color: $clr-sky-blue;
	height: 100vh;

	.swiper {
		width: 100%;
		height: 100%;
		overflow: hidden;

		@include breakpoint-480 {
			padding-bottom: 50px;
		}
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
	}

	.swiper-pagination-bullet {
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		color: $clr-black;
		opacity: 1;
		background: rgba(0, 0, 0, 0.2);
	}

	.swiper-pagination-bullet-active {
		color: $clr-white;
		background: #007aff;
	}

	.h-banner-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		max-width: 1140px;
		width: 100%;

		@include breakpoint-1199 {
			max-width: 991px;
			margin-left: 60px;
		}

		@include breakpoint-991 {
			margin-top: 100px;
		}

		@include breakpoint-640 {
			display: block;
			margin-top: 60px;
			margin-left: 15px;
		}

		@include breakpoint-480 {
			margin-top: 120px;
		}

		.h-banner-left {
			width: 50%;

			@include breakpoint-640 {
				width: 75%;
				margin-bottom: 30px;
			}

			@include breakpoint-480 {
				width: calc(100% - 15px);
				margin-bottom: 30px;
			}
		}

		.h-banner-right {
			width: 50%;
			position: relative;
			z-index: 1;

			@include breakpoint-640 {
				width: 100%;
			}
		}

		.h-banner-left {
			.h-bnr-top {
				transition: $transition;

				h2 {
					font-size: 70px;
					color: $clr-white;
					text-transform: uppercase;
					font-family: $heading-font;
					font-weight: 800;
					margin-bottom: 20px;

					span {
						display: block;
						-webkit-text-fill-color: transparent;
						-webkit-text-stroke: 2px #fff;
						font-size: 80px;
					}

					@include breakpoint_1199 {
						font-size: 60px;

						span {
							font-size: 70px;
						}
					}

					@include breakpoint_991 {
						font-size: 50px;

						span {
							font-size: 60px;
						}
					}

					@include breakpoint_767 {
						font-size: 40px;

						span {
							font-size: 46px;
						}
					}
				}

				p {
					font-size: 18px;
					color: $clr-white;
					margin-bottom: 40px;

					@include breakpoint_1199 {
						font-size: 16px;
					}

					@include breakpoint_767 {
						margin-bottom: 14px;
						font-size: 16px;
					}
				}
			}
		}

		.h-bnr-btn {
			outline: none;
			color: $clr-white;
			padding: 12px 30px;
			letter-spacing: 1px;
			position: relative;
			display: inline-table;
			background-color: transparent;
			border: 1px solid $clr-white;
			font-size: 18px;
			text-transform: uppercase;
			transition: 0.5s all ease;

			&:hover {
				background-color: $clr-primary;
				border: 1px solid transparent;
			}

			@include breakpoint_767 {
				padding: 8px 15px;
				font-size: 14px;
			}
		}

	}

	.h-bnr-r-inner {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);

		@include breakpoint-640 {
			position: inherit;
			transform: none;
			top: auto;
			right: auto;
		}

		.h-bnr-media {
			img {
				position: relative;
				left: 0;
				top: 50px;

				@include breakpoint-1199 {
					left: -38px;
				}

				@include breakpoint-991 {
					left: -58px;
					width: 70%;
				}

				@include breakpoint-640 {
					left: 0px;
				}
			}
		}
	}

	.h-bnr-animation {
		width: 670px;
		height: 680px;
		position: absolute;
		left: 100%;
		top: 0px;
		margin-left: -285px;
		z-index: -1;

		@include breakpoint-1199 {
			width: 360px;
			height: 580px;
			margin-left: -286px;
		}

		@include breakpoint-991 {
			width: 360px;
			height: 450px;
			margin-left: -360px;
		}

		@include breakpoint-768 {
			width: 290px;
			height: 350px;
			margin-left: -290px;
		}

		@include breakpoint-640 {
			width: 350px;
			height: 460px;
			margin-left: 0px;
			left: auto;
		}

		@include breakpoint-480 {
			width: calc(100% - 15px);
		}
	}

	.cross-line-box {
		position: absolute;
		z-index: -1;

		&.left {
			left: 10%;
			top: 20%;
		}

		&.right {
			right: 30px;
			bottom: 60px;
			z-index: 1;
		}
	}

	.circle-left-top {
		width: 500px;
		height: 500px;
		border-radius: 50%;
		background-color: $clr-white;
		opacity: 0.03;
		position: absolute;
		left: -150px;
		top: -200px;
		z-index: -1;
	}

	.swiper-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		right: 30px;
		left: auto;
		width: auto;
		top: 50%;
		transform: translateY(-50%);
		bottom: auto;
		border-right: 6px solid rgba(255, 255, 255, 0.5);
		padding-right: 10px;

		@include breakpoint-480 {
			border: 0px;
			position: inherit;
			right: auto;
			left: auto;
			top: auto;
			width: auto;
			transform: none;
			padding-right: 0px;
			margin-top: 6px;
		}

		.swiper-pagination-bullet {
			font-size: 30px;
			line-height: 30px;
			opacity: 0.5;
			background-color: transparent;
			display: block !important;
			color: $clr-white;
			height: auto;
			width: 60px;
			text-align: center;
			margin-bottom: 15px !important;
			position: relative;

			@include breakpoint-640 {
				font-size: 16px;
				line-height: 16px;
				width: 30px;
			}

			@include breakpoint-480 {
				display: inline-block !important;
			}

			&::before {
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;
				content: '0';
				font-size: 30px;
				line-height: 30px;

				@include breakpoint-640 {
					font-size: 16px;
					line-height: 16px;
				}

			}

			&:last-child {
				margin-bottom: 0px !important;
			}

			&.swiper-pagination-bullet-active {
				font-size: 60px;
				line-height: 60px !important;
				opacity: 1;
				position: relative;

				@include breakpoint-640 {
					font-size: 40px;
					line-height: 40px !important;
					width: 40px;
				}

				&::before {
					position: absolute;
					left: -25px;
					top: 0px;
					bottom: 0px;
					content: '0';
					font-size: 60px;
					line-height: 60px !important;

					@include breakpoint-640 {
						font-size: 40px;
						line-height: 40px !important;
						width: 40px;
					}
				}

				&::after {
					position: absolute;
					right: -20px;
					top: 0px;
					bottom: 0px;
					content: '';
					border-right: 6px solid $clr-white;

					@include breakpoint-480 {
						display: none;
					}
				}
			}
		}
	}
}

.slide-top {
	animation: slide-top 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate-reverse both;
}

@keyframes slide-top {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-50px);
	}
}

.zoon-in-out {
	animation: zoom-in-zoom-out 8s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1.1, 1.1);
	}

	50% {
		transform: scale(1.5, 1.5);
	}

	100% {
		transform: scale(1.1, 1.1);
	}
}

.up-down {
	animation: slide-top 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-20px);
	}
}

/* Shine Animation*/

.shine-effect {
	.shine-box {
		position: relative;
		overflow: hidden;

		&:before {
			position: absolute;
			top: 0;
			left: -90%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
			transform: skewX(-25deg);
		}

		&:hover {
			&:before {
				animation: shine .75s;
			}
		}
	}
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}

@keyframes shine {
	100% {
		left: 125%;
	}
}


select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* Remove default arrow */
	background-image: url(../public/favicon.jpg);
	/* Add custom arrow */
	padding: 10px 30px 10px 10px;
	background-color: #fff;
}



/*Home Page 2*/
/*Home 1 banner*/

.twm-slider2-wrap {
	background-color: $clr-sky-blue;
	height: 100vh;

	.swiper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
		background-position: bottom right;

		@include breakpoint-991 {
			background-position: bottom;
		}

		@include breakpoint-640 {
			background-position: bottom;
		}

	}

	.swiper-pagination-bullet {
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		color: $clr-black;
		opacity: 1;
		background: rgba(0, 0, 0, 0.2);
	}

	.swiper-pagination-bullet-active {
		color: $clr-white;
		background: #007aff;
	}

	.h-banner-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		max-width: 1140px;
		width: 100%;
		margin-top: 60px;

		@include breakpoint-1199 {
			max-width: 991px;
			margin-left: 60px;
		}

		@include breakpoint-991 {
			margin-top: 100px;
		}

		@include breakpoint-640 {
			display: block;
			margin: 0px;
		}

		@include breakpoint-480 {
			margin-top: 60px;
		}

		.h-banner-left {
			width: 50%;

			@include breakpoint-640 {
				width: calc(100% - 100px);
				margin: 0px auto;
				text-align: center;
				background-color: rgba(0, 0, 0, 0.6);
				padding: 30px;
			}

			@include breakpoint-480 {
				width: calc(100% - 15px);
				margin-bottom: 30px;
			}
		}

		.h-banner-left {
			.h-bnr-top {
				transition: $transition;

				h2 {
					font-size: 120px;
					color: $clr-white;
					text-transform: uppercase;
					font-family: $heading-font;
					font-weight: 800;
					margin-bottom: 20px;

					span {
						display: block;
						font-size: 140px;
						color: $clr-primary;
						//text-shadow: 1px 1px 10px $clr-white;
					}

					@include breakpoint_1199 {
						font-size: 60px;

						span {
							font-size: 70px;
						}
					}

					@include breakpoint_767 {
						font-size: 50px;

						span {
							font-size: 60px;
						}
					}

					@include breakpoint_640 {
						font-size: 40px;

						span {
							font-size: 50px;
						}
					}
				}

				p {
					font-size: 24px;
					color: $clr-white;
					margin-bottom: 40px;

					@include breakpoint_1199 {
						font-size: 18px;
					}

					@include breakpoint_767 {
						margin-bottom: 15px;
						font-size: 16px;
					}
				}
			}
		}

		.h-bnr-btn {
			outline: none;
			color: $clr-white;
			padding: 12px 30px;
			letter-spacing: 1px;
			position: relative;
			display: inline-table;
			background-color: transparent;
			border: 1px solid $clr-white;
			font-size: 18px;
			text-transform: uppercase;
			transition: 0.5s all ease;

			&:hover {
				background-color: $clr-primary;
				border: 1px solid transparent;
			}

			@include breakpoint_767 {
				padding: 8px 15px;
				font-size: 14px;
			}
		}

	}

	.cross-line-box {
		position: absolute;
		z-index: -1;

		&.left {
			left: 10%;
			top: 20%;
		}

		&.right {
			right: 30px;
			bottom: 60px;
			z-index: 1;
		}
	}

	.circle-left-top {
		width: 500px;
		height: 500px;
		border-radius: 50%;
		background-color: $clr-white;
		opacity: 0.03;
		position: absolute;
		left: -150px;
		top: -200px;
		z-index: -1;
	}

	.swiper-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		left: 50%;
		bottom: 30px;
		width: auto;
		transform: translateX(-50%);
		border-bottom: 6px solid rgba(255, 255, 255, 0.5);
		padding-bottom: 10px;

		@include breakpoint-420 {
			width: 180px;
		}

		.swiper-pagination-bullet {
			font-size: 20px;
			line-height: 20px;
			opacity: 0.5;
			background-color: transparent;
			display: inline-block !important;
			color: $clr-white;
			height: auto;
			width: 40px;
			text-align: center;
			margin-right: 15px !important;
			position: relative;

			@include breakpoint-640 {
				font-size: 16px;
				line-height: 16px;
				width: 40px;
			}

			@include breakpoint-480 {
				display: inline-block !important;
			}

			&::before {
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;
				content: '0';
				font-size: 20px;
				line-height: 20px;

				@include breakpoint-640 {
					font-size: 16px;
					line-height: 16px;
				}

			}

			&:last-child {
				margin-right: 0px !important;
			}

			&.swiper-pagination-bullet-active {
				font-size: 34px;
				padding-left: 20px;
				line-height: 34px !important;
				opacity: 1;
				position: relative;

				&::before {
					position: absolute;
					left: 0px;
					top: 0px;
					bottom: 0px;
					content: '0';
					font-size: 34px;
					line-height: 34px !important;
				}

				&::after {
					position: absolute;
					left: -2px;
					bottom: -18px;
					width: 40px;
					content: '';
					border-top: 6px solid $clr-white;

					@include breakpoint-480 {
						display: none;
					}
				}
			}

		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		color: $clr-white;
	}
}


.tw-project-2-wrap {
	.tw-project-2-content {
		position: relative;

		.tw-project-2-content-position {
			width: calc(100% - 60px);
			padding: 65px 48px;
			position: relative;
			z-index: 1;
			margin: 0px auto;

			@include breakpoint-480 {
				padding: 15px;
				width: calc(100% - 15px);
			}

			&:after {
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				right: 0px;
				bottom: 0px;
				background-color: $clr-white;
				z-index: -1;
			}
		}
	}
}

/*Footer dark version css*/
.footer-light {
	color: $clr-dark;
	font-size: 16px;

	.ftr-bg {
		background-color: #efefef;
	}

	.logo-footer {
		margin-bottom: 25px;
		max-width: 174px;
	}

	.footer-top {
		padding: 80px 0px 0px 0px;

		@include breakpoint_991 {
			padding: 30px 0px 0px 0px;
		}
	}

	.widget-title {
		font-family: $common-font;
		color: $clr-dark;
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 30px;
		padding-bottom: 23px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
			background-color: $clr-primary;
			width: 60px;
			height: 3px;
		}
	}

	.social-icons {
		margin: 40px 0px 0px 0px;

		li {
			display: inline-block;

			a {
				height: 45px;
				width: 45px;
				background-color: #184f6e;
				line-height: 45px;
				padding: 0px;
				color: $clr-white;
				font-size: 22px;
				text-align: center;
				margin-right: 3px;

				&:hover {
					color: $clr-white;
					background-color: #051721;
				}
			}
		}
	}

	.footer-bottom {
		padding: 0px;
		color: $clr-white;
		position: relative;
		z-index: 1;
		font-weight: 400;

		.footer-bottom-info {
			display: flex;
			padding: 30px 0px;
			justify-content: center;
			align-items: center;
			border-top: 1px solid $clr-dark;

			@include breakpoint_991 {
				display: block;
			}

		}

		.footer-copy-right {
			@include breakpoint_991 {
				margin: 5px 10px 5px 0px;
				display: inline-block;
			}

			.copyrights-text {
				color: $clr-dark;
				font-size: 14px;
			}
		}

	}

	.widget_services {
		ul {
			li {
				a {
					color: $clr-dark;
					position: relative;
					padding: 0px 0px 5px 0px;
					display: block;
					margin-left: 0px;
					transition: 0.5s all ease;
					font-size: 16px;
				}
			}
		}
	}
}

/*Why Choose Section*/
.tw-why-choose-area-top2 {
	background-position: bottom right;

	@include breakpoint-767 {
		background-size: auto;
	}
}

.tw-why-choose-area2 {
	padding-bottom: 100px;

	@include breakpoint-991 {
		padding-bottom: 40px;
	}
}

.tw-why-choose-section2 {
	.row {
		align-items: center;

		@include breakpoint-991 {
			padding-bottom: 0px;
		}
	}

	.tw-why-choose-left {
		@include breakpoint-991 {
			margin-bottom: 50px;
		}

		@include breakpoint-480 {
			margin-bottom: 40px;
		}

		strong {
			font-size: 18px;
			line-height: 26px;
			font-weight: 400;
			margin-bottom: 20px;
			display: block;
		}

		.tw-why-choose-left-bottom {
			padding-top: 30px;
			display: flex;

			@include breakpoint-480 {
				display: block;
			}

			.site-button {
				margin-right: 30px;
			}

			.sign-font {
				position: relative;

				span {
					font-family: 'Sacramento', cursive;
					font-size: 45px;
					color: $clr-black;
					position: absolute;
					left: 0px;
					top: 0px;
					transform: rotate(-20deg);

					@include breakpoint-480 {
						position: relative;
					}
				}
			}
		}
	}

	.tw-why-choose-right {
		position: relative;
		margin-right: 60px;
		z-index: 1;

		@include breakpoint-991 {
			margin-right: 0px;
			margin-bottom: 50px;
		}

		&:after {
			content: '';
			width: 470px;
			height: 470px;
			border-radius: 50%;
			background-color: $clr-primary;
			opacity: 1;
			position: absolute;
			left: 50px;
			bottom: 15px;
			z-index: -1;

			@include breakpoint-1199 {
				width: 380px;
				height: 380px;
			}

			@include breakpoint-480 {
				display: none;
			}
		}

		&:before {
			content: '';
			width: 500px;
			height: 500px;
			border-radius: 50%;
			border: 2px solid $clr-primary;
			opacity: 1;
			position: absolute;
			left: 50px;
			bottom: 0px;
			z-index: -1;

			@include breakpoint-1199 {
				width: 400px;
				height: 400px;
				bottom: 4px;
			}

			@include breakpoint-480 {
				display: none;
			}
		}

		.tw-why-choose-media1 {
			position: relative;
			max-width: 370px;
			box-shadow: 0px 40px 60px rgba(30, 143, 208, 0.7);

			@include breakpoint-1199 {
				max-width: 300px;
			}

			img {
				width: 100%;
			}
		}

		.tw-why-choose-tag {
			position: absolute;
			width: 260px;
			padding: 20px 20px 20px 50px;
			right: 0px;
			bottom: 50px;
			z-index: 2;
			background-color: $clr-dark;
			border-right: 6px solid $clr-primary;

			.tag-box {
				h2 {
					color: $clr-white;
					font-size: 72px;
				}

				h3 {
					color: $clr-primary;
				}

				span {
					display: block;
					color: $clr-white;
					position: absolute;
					left: 20px;
					bottom: 7px;
					transform-origin: 0px;
					transform: rotate(-90deg);
				}
			}
		}
	}
}

/*Testimonial 2*/
.tw-testimonial-2-area {
	overflow: hidden;

	.tw-testimonial-2-area-inner {
		position: relative;
		z-index: 1;
		padding-top: 120px;

		@include breakpoint-575 {
			padding-top: 0px;
		}
	}

	.tw-outer-border {
		position: absolute;
		content: '';
		top: 0px;
		left: 50%;
		bottom: 0px;
		max-width: 1140px;
		width: 100%;
		border: 70px solid #1e8fd0;
		;
		transform: translateX(-50%);

		@include breakpoint-991 {
			border: 40px solid #1e8fd0;
		}

		@include breakpoint-575 {
			border: 0px;
		}
	}

	.slider-testimonial-2-wrap {
		position: relative;
		z-index: 1;
		padding: 0px 80px;

		@include breakpoint-991 {
			padding: 0px;
		}

		.slick-prev,
		.slick-next {
			top: auto;
			bottom: -30px;
			width: 30px;
			height: 30px;

			&:before {
				color: $clr-white;
				font-family: 'FontAwesome';
				background-color: $clr-dark;
				opacity: 1;
				width: 30px;
				height: 30px;
				line-height: 30px;
				display: block;
			}

			@include breakpoint-460 {
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.slick-prev {
			left: 4px;

			&:before {
				content: "\f104";
			}
		}

		.slick-next {
			right: 4px;

			&:before {
				content: "\f105";
			}
		}

	}

	.slick-testimonials-2-thumb {
		position: relative;
		z-index: 1;
		max-width: 300px;
		left: 50%;
		top: -15px;
		transform: translateX(-50%);

		.slick-testimonials-2-thumbpic {
			cursor: pointer;
			margin-bottom: 15px;
			padding-right: 15px;
			transition: $transition;
			position: relative;
			z-index: 1;

			@include breakpoint-480 {
				padding: 0px
			}

			img {
				width: 100%;
				border: 3px solid #fff;
			}
		}

		.slick-list {
			padding: 0px !important;
		}

		.slick-arrow {
			display: none !important;
		}

		.slick-active {
			&.slick-center {
				.slick-testimonials-2-thumbpic {
					img {
						border: 3px solid $clr-primary;
					}

					@include breakpoint-480 {
						img {
							border-color: $clr-primary;
						}

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}

	.tw-testimonials2-section {
		text-align: center;
		padding: 0px 50px;
		margin-bottom: 80px;

		@include breakpoint_1270 {
			padding-right: 30px;
		}

		@include breakpoint-991 {
			padding: 0px 15px;
		}

		@include breakpoint_480 {
			padding-right: 35px;
			padding-left: 35px;
		}

		@include breakpoint_460 {
			margin-bottom: 40px;
		}

		.tw-testimonials2-text {
			margin-bottom: 40px;
			position: relative;
			font-size: 14px;
			font-style: italic;

			@include breakpoint_480 {
				margin-bottom: 15px;
			}

			.tw-testimonials2-quote {
				display: block;
				margin-bottom: 30px;
				text-align: center;

				@include breakpoint_480 {
					margin-bottom: 15px;
				}

				img {
					display: inline-block;
				}
			}
		}

		.tw-testimonials2-name {
			font-size: 30px;
			line-height: 38px;
			font-family: 'Sacramento', cursive;
			color: $clr-dark;
			font-weight: 600;
			text-align: center;
		}

		.tw-testimonials2-postion {
			font-size: 14px;
			color: $clr-primary;
			position: relative;
			text-align: center;
		}
	}
}



/*Home 3 banner*/
.slider-scale {
	transition: all 8s linear;
	transform: translateX(30%) scale(0.8);
}

.slider-scale2 {
	transition: all 8s linear;
	transform: translateX(30%);
}

.twm-slider3-wrap {
	background-color: $clr-sky-blue;
	height: 100vh;

	.swiper-slide-active {
		.slider-scale {
			transform: translateX(0%) scale(1);
		}

		.slider-scale2 {
			transform: translateX(0%);
		}
	}

	.swiper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;

		@include breakpoint-1365 {
			padding: 0px 50px;
		}
	}

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		color: $clr-black;
		opacity: 1;
		background: $clr-white;
		transition: $transition;

		&.swiper-pagination-bullet-active {
			background-color: $clr-primary;
		}
	}


	.h-banner-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		max-width: 1230px;
		width: 100%;

		@include breakpoint-1199 {
			max-width: 991px;
			margin-left: 60px;
		}

		@include breakpoint-991 {
			margin-top: 100px;
		}

		@include breakpoint-768 {
			display: block;
			margin-top: 60px;
			margin-left: 0px;
		}

		@include breakpoint-480 {
			margin-top: 120px;
		}

		.h-banner-left {
			width: 50%;

			@include breakpoint-768 {
				width: 75%;
				margin: 100px auto 30px;
				text-align: center;
			}

			@include breakpoint-736 {
				width: 50%;
				margin: 0px;
				text-align: left;
			}

			@include breakpoint-640 {
				width: 75%;
				margin: 100px auto 30px;
				text-align: center;
			}

			@include breakpoint-575 {
				width: 100%;
			}

			.h-bnr-top {
				transition: $transition;

				.title-small {
					display: block;
					font-size: 36px;
					font-family: $heading-font;
					color: $clr-white;
					font-weight: 600;
					margin-bottom: 20px;

					@include breakpoint_1199 {
						font-size: 32px;
					}

					@include breakpoint_991 {
						font-size: 20px;
					}

					@include breakpoint_767 {
						font-size: 18px;
					}
				}

				h2 {
					font-size: 66px;
					color: $clr-white;
					text-transform: uppercase;
					font-family: $heading-font;
					font-weight: 800;
					margin-bottom: 20px;

					@include breakpoint_1365 {
						font-size: 60px;
						margin-bottom: 0px;
					}

					@include breakpoint_1199 {
						font-size: 45px;
					}

					@include breakpoint_991 {
						font-size: 40px;
					}

					@include breakpoint_767 {
						font-size: 26px;
					}
				}
			}
		}

		.h-banner-right {
			width: 50%;
			position: absolute;
			right: 0px;
			z-index: 1;

			@include breakpoint-768 {
				width: 100%;
				position: relative;
			}

			@include breakpoint-736 {
				width: 50%;
				position: absolute;
				right: 0px;
				top: 50%;
				transform: translateY(-50%);
			}

			@include breakpoint-640 {
				width: 100%;
				position: relative;
				top: auto;
				transform: inherit;
			}
		}

		.h3-bnr-btn {
			margin-top: 40px;
			text-transform: uppercase;
		}

	}

	.h-bnr-r-inner {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);

		@include breakpoint-768 {
			position: inherit;
			transform: none;
			top: auto;
			right: auto;
		}

		@include breakpoint-736 {
			position: absolute;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
		}

		@include breakpoint-640 {
			position: inherit;
			transform: none;
			top: auto;
			right: auto;
		}

		.h-bnr-media {
			img {
				position: relative;
				left: 0;
				top: 50px;

				@include breakpoint-768 {
					left: 0px;
				}
			}

			.trck-animation-wrap {
				position: relative;
				bottom: -100px;

				@include breakpoint-768 {
					bottom: 50px;
					width: 75%;
					margin-left: auto;
				}

				@include breakpoint-736 {
					bottom: 0px;
					width: 100%;
					margin-left: auto;
				}

				@include breakpoint-640 {
					bottom: 50px;
					width: 75%;
					margin-left: auto;
				}

				.tyre-1 {
					position: absolute;
					left: 12.9%;
					bottom: 8%;
					width: 12%;
				}

				.tyre-2 {
					position: absolute;
					right: 42.8%;
					bottom: 8%;
					width: 12%;
				}

				.tyre-3 {
					position: absolute;
					right: 16.8%;
					bottom: 8%;
					width: 12%;
				}

				.truck-light {
					position: absolute;
					right: calc(100% - 4%);
					top: 67%;
					width: 400px;

					@include breakpoint-1199 {
						width: 347px;
					}

					@include breakpoint-768 {
						width: 200px;
					}
				}
			}
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		color: $clr-white;
	}
}

/*truck light blink*/
.blink-image {
	animation: blink normal 2s infinite ease-in-out;
	/* Opera and prob css3 final iteration */
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 1;
	}
}

/*Slide top fast*/
.slide-top-fast {
	animation: slide-top-fast 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top-fast {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-40px);
	}
}


/*Slide top slow*/
.slide-top-slow {
	animation: slide-top-slow 2.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate-reverse both;
}

@keyframes slide-top-slow {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

/* Tyre Spin */
.spin-tyres {
	animation-name: spin;
	animation-duration: 5000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(-360deg);
	}
}


/*Cloud Animation*/
.ani-clowd1,
.ani-clowd2,
.ani-clowd3,
.ani-clowd4 {
	position: absolute;

	img {
		width: 100%;
	}
}

.ani-clowd1 {
	left: 0%;
	top: 0%;
	width: 40%;
}

.ani-clowd2 {
	left: 0%;
	top: 0%;
	width: 40%;
}

.ani-clowd3 {
	left: 0%;
	top: 150px;
	width: 40%;
}

.clowd1-move {
	animation: animateCloud1 25s linear infinite;
}

.clowd2-move {
	animation: animateCloud2 45s linear infinite;
}

.clowd3-move {
	animation: animateCloud3 35s linear infinite;
}

/* animateCloud1 */
@keyframes animateCloud1 {
	0% {
		margin-left: -40%;
	}

	100% {
		margin-left: 100%;
	}
}

/* animateCloud2 */
@keyframes animateCloud2 {
	0% {
		margin-left: -40%;
	}

	100% {
		margin-left: 100%;
	}
}

/* animateCloud3 */
@keyframes animateCloud3 {
	0% {
		margin-left: -40%;
	}

	100% {
		margin-left: 100%;
	}
}


/*What We do 3*/
.tw-what-wedo-area3 {
	overflow: hidden;
}

.tw-what-wedo-section3 {
	@include breakpoint-991 {
		margin-bottom: 30px;
	}

	.tw-what-wedo-media3 {
		position: relative;
		height: 100%;
		z-index: 1;

		.truck-2 {
			position: absolute;
			right: -50px;
			bottom: 0px;
			z-index: 1;
			max-width: inherit;

			@include breakpoint-1199 {
				max-width: 540px;
				right: auto;
			}

			@include breakpoint-991 {
				position: relative;
			}

			@include breakpoint-575 {
				max-width: 440px;
			}

			@include breakpoint-460 {
				max-width: 300px;
			}
		}

		.truck-2-road {
			position: absolute;
			left: 50px;
			bottom: 0px;
			z-index: 0;
			max-width: inherit;

			@include breakpoint-991 {
				left: 0;
			}

			@include breakpoint-575 {
				max-width: 85%;
			}

			@include breakpoint-460 {
				max-width: 75%;
			}
		}

		.truck-2-bg {
			background-color: $clr-primary;
			width: 3500px;
			position: absolute;
			left: 90px;
			top: 0px;
			bottom: 80px;
			z-index: -1;
		}
	}
}

.tw-service-icon-box-wrap3 {
	position: relative;
}

.service-icon-box-three {
	position: relative;
	margin-bottom: 5px;
	padding: 20px;
	background-color: $clr-white;
	transition: all 0.3s linear;
	z-index: 0;

	&:before {
		width: 8px;
		content: '';
		position: absolute;
		z-index: 1;
		opacity: 0.5;
		left: -8px;
		top: 30px;
		bottom: 30px;
		background-color: $clr-primary;
		transition: all 0.3s linear;
	}


	&:hover {
		box-shadow: 0px 0px 50px rgba(30, 143, 208, 0.5);
		z-index: 999;

		&:before {
			left: 0%;
			opacity: 1;
		}
	}

	.service-icon-box-three-media {
		margin-right: 30px;
		margin-top: 20px;
		float: left;
		width: 70px;

		@include breakpoint-400 {
			margin-right: 20px;
			width: 40px;
		}
	}

	.service-icon-box-title {
		overflow: hidden;

		.wt-title {
			margin-bottom: 15px;

			span {
				font-size: 32px;
				padding-right: 5px;
			}

			@include breakpoint_767 {
				padding-right: 0px;
			}
		}

		p {
			margin-bottom: 0px;
		}
	}

	&.site-bg-black {
		.service-icon-box-title {
			.wt-title {
				a {
					color: $clr-white;
				}
			}
		}
	}
}

/*Why choose us*/

.tw-why-choose-area3-top {
	background-position: bottom right;

	@include breakpoint-767 {
		background-size: auto;
	}
}

.tw-why-choose3-section {
	.row {
		align-items: center;

		@include breakpoint-991 {
			padding-bottom: 0px;
		}
	}

	.tw-why-choose-left {
		@include breakpoint-991 {
			margin-bottom: 50px;
		}

		@include breakpoint-480 {
			margin-bottom: 40px;
		}

		strong {
			font-size: 18px;
			line-height: 26px;
			font-weight: 400;
			margin-bottom: 20px;
			display: block;
		}

		.tw-why-choose-left-bottom {
			padding-top: 30px;
			display: flex;

			@include breakpoint-480 {
				display: block;
			}

			.site-button {
				margin-right: 30px;
			}

			.sign-font {
				position: relative;

				span {
					font-family: 'Sacramento', cursive;
					font-size: 45px;
					color: $clr-black;
					position: absolute;
					left: 0px;
					top: 0px;
					transform: rotate(-20deg);

					@include breakpoint-480 {
						position: relative;
					}
				}
			}
		}
	}

	.tw-why-choose-right {
		@include breakpoint-991 {
			margin-bottom: 30px;
		}

		position: relative;

		.tw-why-choose-media1 {
			position: relative;
			z-index: 1;
			max-width: 358px;
			padding-top: 160px;
			margin-left: auto;
			padding-right: 20px;

			@include breakpoint-480 {
				padding-top: 0px;
				max-width: 100%;
				margin-bottom: 40px;
				padding-right: 0px;
			}

			img {
				width: 100%;
			}
		}

		.tw-why-choose-media2 {
			position: absolute;
			max-width: 338px;
			left: 0px;
			top: 0px;
			z-index: 2;

			@include breakpoint-480 {
				position: inherit;
				max-width: 100%;
				margin-left: 0px;
				margin-bottom: 30px;

				img {
					width: 100%;
				}
			}

			&:after {
				position: absolute;
				content: '';
				border-right: 40px solid $clr-white;
				border-bottom: 40px solid $clr-white;
				right: -40px;
				bottom: -40px;
				height: 100%;
				width: 100%;
				z-index: -1;
				box-shadow: 0px 40px 60px rgba(30, 143, 208, 0.7);

				@include breakpoint-480 {
					border-right: 20px solid #fff;
					border-bottom: 20px solid #fff;
					right: -20px;
					bottom: -20px;
				}
			}
		}
	}
}

/*Booking area 3*/
.tw-booking-area-bg-wrap {
	position: relative;
	z-index: 1;
	overflow: hidden;

	.tw-booking-area-bg {
		background-position: left bottom 100px;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		position: absolute;
		left: -200px;
		top: 0px;
		z-index: -1;

		@include breakpoint-1199 {
			left: -322px;
			top: -70px;
		}

		@include breakpoint-991 {
			display: none;
		}
	}

}

.tw-estimation-area3 {
	@include breakpoint-991 {
		.row {
			[class*='col-']:last-child {
				.tw-est-section-block3 {
					margin-top: 0px;
				}
			}
		}
	}

	@include breakpoint-767 {
		.row {
			padding: 10px 0px;

			[class*='col-']:last-child {
				.tw-est-section-block3 {
					margin-top: 15px;
				}
			}
		}
	}
}

/*Estimation section 3*/
.tw-est-section-block3 {
	height: 550px;
	position: relative;
	z-index: 1;
	border: 15px solid transparent;
	background-color: rgba(0, 0, 0, 0.4);
	text-align: center;
	transition: $transition;

	@include breakpoint-991 {
		height: auto;
		margin-top: 25px;
		margin-bottom: 25px;
	}

	@include breakpoint-767 {
		height: auto;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.tw-est-section-block-content {
		position: absolute;
		left: 0px;
		bottom: 0px;
		color: $clr-white;
		padding: 10px;
		transform: translate3d(0%, 0, 0);
		transition: opacity 0.35s, transform 0.8s;

		@include breakpoint-991 {
			position: relative;
		}

		.tw-est-section-number {
			font-size: 80px;
			font-family: $heading-font;
			font-weight: 700;

			@include breakpoint-991 {
				font-size: 40px;
			}
		}

		.tw-title {
			color: $clr-white;
		}
	}

	&:hover {
		border: 15px solid rgba(255, 255, 255, 0.5);
		background-color: $clr-white;

		.tw-est-section-block-content {
			transform: translate3d(0%, -100px, 0);
			color: $clr-primary;

			@include breakpoint-991 {
				transform: translate3d(0%, -20px, 0);
			}

			.tw-title {
				color: $clr-dark;
			}

			p {
				color: $clr-dark;
			}

			.site-button-2-outline {
				border: 2px solid $clr-dark;

				i {
					color: $clr-dark;

					&:after {
						background-color: $clr-dark;
					}
				}
			}
		}
	}
}


/*Testimonial 3*/
.tw-testimonial-3-area {
	overflow: hidden;

	.tw-testimonial-3-area-inner {
		position: relative;
		z-index: 1;
		padding-top: 125px;
		padding-bottom: 125px;

		@include breakpoint-575 {
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}

	.tw-outer-border {
		position: absolute;
		content: '';
		top: 0px;
		left: 50%;
		bottom: 0px;
		max-width: 100%;
		width: 100%;
		border: 70px solid #1e8fd0;
		;
		transform: translateX(-50%);

		@include breakpoint-991 {
			border: 40px solid #1e8fd0;
		}

		@include breakpoint-575 {
			border: 0px;
		}
	}

	.slider-testimonial-3-wrap {
		position: relative;
		z-index: 1;
		padding: 0px 80px;

		@include breakpoint-991 {
			padding: 0px 50px;
		}

		@include breakpoint-575 {
			padding: 0px;
		}

		.slick-prev,
		.slick-next {
			top: 50%;
			transform: translateY(-50%);
			width: 30px;
			height: 30px;

			&:before {
				color: $clr-white;
				font-family: 'FontAwesome';
				background-color: $clr-dark;
				opacity: 1;
				width: 30px;
				height: 30px;
				line-height: 30px;
				display: block;
			}

			@include breakpoint-460 {
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.slick-prev {
			left: 4px;

			&:before {
				content: "\f104";
			}
		}

		.slick-next {
			right: 4px;

			&:before {
				content: "\f105";
			}
		}

	}

	.slick-testimonials-3-thumb {
		position: relative;
		z-index: 1;
		max-width: 300px;
		left: 50%;
		top: -15px;
		transform: translateX(-50%);

		.slick-testimonials-3-thumbpic {
			cursor: pointer;
			margin-bottom: 15px;
			padding-right: 15px;
			transition: $transition;
			position: relative;
			z-index: 1;

			@include breakpoint-480 {
				padding: 0px
			}

			img {
				width: 100%;
				border: 3px solid #fff;
			}
		}

		.slick-list {
			padding: 0px !important;
		}

		.slick-arrow {
			display: none !important;
		}

		.slick-active {
			&.slick-center {
				.slick-testimonials-3-thumbpic {
					img {
						border: 3px solid $clr-primary;
					}

					@include breakpoint-480 {
						img {
							border-color: $clr-primary;
						}

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}

	.tw-testimonials3-section {
		text-align: center;
		padding: 0px 50px;
		margin-bottom: 80px;

		@include breakpoint_1270 {
			padding-right: 30px;
		}

		@include breakpoint-991 {
			padding: 0px 15px;
		}

		@include breakpoint_575 {
			margin-bottom: 40px;
		}

		.tw-testimonials3-text {
			margin-bottom: 40px;
			position: relative;
			font-size: 14px;
			font-style: italic;

			@include breakpoint-575 {
				padding: 0px 35px;
			}

			@include breakpoint_480 {
				margin-bottom: 15px;
				padding: 0px 15px;
			}

			.tw-testimonials3-quote {
				display: block;
				margin-bottom: 30px;
				text-align: center;

				@include breakpoint_480 {
					margin-bottom: 15px;
				}

				img {
					display: inline-block;
				}
			}
		}

		.tw-testimonials3-name {
			font-size: 30px;
			line-height: 38px;
			font-family: 'Sacramento', cursive;
			color: $clr-dark;
			font-weight: 600;
			text-align: center;
		}

		.tw-testimonials3-postion {
			font-size: 14px;
			color: $clr-primary;
			position: relative;
			text-align: center;
		}
	}
}


/*Client carousel 2*/
.home-client3-outer {
	position: relative;
	top: -140px;
	margin-bottom: -140px;

	@include breakpoint-991 {
		margin-bottom: -110px;
	}

	@include breakpoint-575 {
		top: 0px;
		margin-bottom: 40px;
	}

	.home-client-carousel3 {
		padding: 50px;
		background-color: $clr-white;
		box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1);

		.client-logo {
			max-width: 130px;
			margin-left: auto;
			margin-right: auto;

			a {
				height: 100px;
				display: flex;
				justify-content: space-around;

				img {
					width: auto;
					height: auto;
					filter: grayscale(100%);
					transition: $transition;
				}

				&:hover {
					img {
						filter: none;
					}
				}
			}

		}
	}
}

/*Services 3*/


.services-gallery-style4 {
	margin-bottom: 30px;

	@include breakpoint_1200 {
		padding-left: 0px;
	}

	@include breakpoint_991 {
		margin-bottom: 30px;
	}

	.owl-carousel .owl-stage-outer {
		position: relative;
		margin-bottom: 30px;
	}



	.services-gallery-one.owl-btn-bottom-left {
		.owl-nav {
			.owl-next {
				right: -1px;
				transition: all 0.2s linear;
			}

			.owl-prev {
				transition: all 0.2s linear;
			}
		}
	}

	.services-gallery-one {
		&.owl-btn-bottom-left {
			.owl-nav {
				opacity: 1;
				position: absolute;
				left: 0px;
				bottom: 0px;

				@include breakpoint_991 {
					text-align: center;
					position: inherit;
					margin-top: 20px;
					bottom: 20px;

					.owl-prev {
						margin-right: 14px;
					}

					.owl-next {
						margin-right: 0px;
					}
				}
			}
		}
	}
}

.service-box-style4 {
	position: relative;
	overflow: hidden;

	.service-content {
		width: 100%;

		.service-content-inner {
			padding: 30px;
			margin: 20px;
			position: relative;
			z-index: 1;

			@include breakpoint-767 {
				margin-left: 0px;
			}

			&:after {
				content: '';
				border: 2px solid $clr-primary;
				position: absolute;
				top: -20px;
				right: -20px;
				bottom: 20px;
				left: 20px;
				z-index: -1;

			}

			&:before {
				content: '';
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
				background-color: $clr-white;
				transition: all 0.2s linear;
			}

			.service-content-top {
				position: relative;

				.service-title-large {
					text-align: right;

					a {
						color: $clr-dark;
						transition: all 0.2s linear;
						font-size: 32px;
						line-height: 0.7;
						font-weight: 600;
						font-family: $heading-font;

						@include breakpoint-991 {
							font-size: 26px;
						}
					}
				}
			}

			.service-content-bottom {
				position: relative;

				.service-title-large-number {
					font-size: 40px;
					color: $clr-primary;
					transition: all 0.2s linear;
					font-weight: 600;
					font-family: $heading-font;

					@include breakpoint-991 {
						font-size: 30px;
					}
				}

				p {
					transition: all 0.2s linear;
					margin-bottom: 20px;
				}

				.site-button-2 {
					color: $clr-dark;
					transition: all 0.2s linear;
				}
			}
		}
	}

	&:hover {

		.service-content-inner {
			&:before {
				background-color: $clr-primary;
			}

			.service-content-top {
				.service-title-large {
					a {
						color: $clr-white;
					}
				}
			}

			.service-content-bottom {
				.service-title-large-number {
					color: $clr-white;
				}

				p {
					color: $clr-white;
				}

				.site-button-2 {
					color: $clr-white;
				}
			}
		}

	}

}



/*Company approch*/
.tw-company-approch-section3 {
	position: relative;
	margin-top: 100px;
	margin-bottom: 90px;

	@include breakpoint-991 {
		margin-top: 30px;
		margin-bottom: 50px;
	}

	@include breakpoint-768 {
		margin-bottom: 30px;
	}

	.tw-company-approch-inner {
		position: relative;
		z-index: 1;

		@include breakpoint-575 {
			margin-left: 0px;
		}

		@include breakpoint-991 {
			.row {

				[class*='col-']:last-child {
					.counter-outer-two {
						margin-bottom: 0px;
					}
				}

			}
		}
	}
}

/*Counter*/
.counter-outer-three-wrap {
	background-color: $clr-white;
	padding: 30px;
	box-shadow: 0px 0px 50px rgba(30, 143, 208, 0.5);
	position: relative;

	@include breakpoint-991 {
		margin-bottom: 60px;
	}

	@include breakpoint-575 {
		margin-bottom: 20px;
	}

	&:before {
		position: absolute;
		content: '';
		top: -60px;
		bottom: -60px;
		right: 0px;
		border-style: solid;
		border-color: $clr-primary;
		border-width: 60px 60px 60px 0px;
		width: 300px;
		z-index: 0;

		@include breakpoint-575 {
			top: -20px;
			bottom: -20px;
			right: 0px;
			border-width: 20px 20px 20px 0px;
			width: 200px;
		}
	}

	.counter-outer-three {
		position: relative;
		margin-bottom: 40px;

		.tw-counter-media {
			position: absolute;
			bottom: 0px;
			left: 0px;

			img {
				height: 70px;

				@include breakpoint-991 {
					height: 46px;
				}
			}

			@include breakpoint-991 {
				img {
					height: 46px;
				}
			}
		}

		.tw-count-number {
			font-size: 28px;
			line-height: 28px;
			margin-bottom: 15px;
			font-weight: 600;
			font-family: $heading-font;

			@include breakpoint_991 {
				font-size: 30px;
				line-height: 30px;
			}
		}

		.counter {
			font-size: 46px;
			line-height: 50px;
			font-weight: 600;
			font-family: $heading-font;

			@include breakpoint_991 {
				font-size: 30px;
				line-height: 30px;
			}

			@include breakpoint_768 {
				font-size: 24px;
			}
		}

		.icon-content {
			overflow: hidden;
			padding-left: 100px;

			@include breakpoint-991 {
				padding-left: 80px;
			}
		}

		.icon-content-info {
			font-weight: 600;
			color: $clr-dark;
			margin-bottom: 0px;
		}

		&:last-child {
			margin-bottom: 0px;
		}
	}

}


/*Text with bg image*/
.tw-company-years3 {
	background-color: $clr-white;

	@include breakpoint-991 {
		display: flex;
		align-items: center;
		margin-bottom: 60px;
	}

	.light {
		position: relative;
		margin-right: 60px;
		display: inline-flex;

		h1 {
			font-size: 350px;
			line-height: 280px;
			display: block;
			color: $clr-dark;
			margin-bottom: 40px;

			@include breakpoint_991 {
				font-size: 250px;
				line-height: 200px;
			}

			@include breakpoint_575 {
				font-size: 100px;
				line-height: 100px;
				margin-bottom: 0px;
			}

		}

		@include breakpoint_991 {
			margin-right: 20px;
		}
	}

	img {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		mix-blend-mode: lighten;
		transform: translateY(-50%);
		margin-top: -5px;
	}

	.tw-company-info {
		max-width: 500px;

		span {
			font-size: 36px;
			line-height: 36px;
			font-weight: 600;
			font-family: $heading-font;
			color: $clr-dark;

			@include breakpoint_991 {
				font-size: 30px;
			}

			@include breakpoint-575 {
				font-size: 26px;
			}

			@include breakpoint-400 {
				font-size: 20px;
				line-height: 20px;
			}
		}
	}
}



/*Faq*/
.tw-faq-section {
	max-width: 950px;
	margin: 0px auto;

	.tw-faq {
		.accordion-item {
			border: 1px solid transparent;
			margin-bottom: 20px;
			overflow: hidden;

			.accordion-button {
				font-size: 20px;
				color: $clr-dark;
				padding: 10px 16px 10px 16px;
				border: 1px solid #efefef;

				@include breakpoint-767 {
					font-size: 16px;
				}

				&:not(.collapsed) {
					box-shadow: none;
				}

				&:focus {
					box-shadow: none !important;
				}
			}
		}
	}
}

.accordion-button:after {
	background-image: none;
	content: "\f067";
	font-family: 'FontAwesome';
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: $clr-primary;
	text-align: center;
}

.accordion-button:not(.collapsed):after {
	background-image: none;
	content: "\f068";
	transform: none;
}


/*Error 404*/
/*-----Error Full Page-----*/
.error-full-page {
	display: flex;
	align-items: center;
	min-height: 100vh;

	.error-full-page-inner {
		display: table-cell;
		text-align: center;
		margin: auto;
		vertical-align: middle;
		position: relative;
	}

	.error-full-page-inner-info {
		.row {
			display: flex;
			align-items: center;

			p {
				color: $clr-white;
				margin-bottom: 40px;
			}

			h3 {
				color: $clr-white;
				font-size: 36px;
			}

			h4 {
				color: $clr-white;
				font-size: 50px;
				margin-bottom: 30px;

				@include breakpoint-991 {
					font-size: 36px;
				}
			}

			strong {
				font-size: 150px;
				line-height: 150px;
				padding-bottom: 20px;
				display: block;
				font-weight: 900;
				color: $clr-primary;
				font-family: $heading-font;

				@include breakpoint-991 {
					font-size: 90px;
					line-height: 90px;
				}

				@include breakpoint-400 {
					font-size: 60px;
					line-height: 60px;
				}
			}
		}

	}
}

/* Terms  */

.terms-page {
	width: 90%;
	margin: 100px auto;
}

.privacy-page {
	width: 90%;
	margin: 100px auto;
}