@import "public/assets/css/variables";
@import "public/assets/css/media-queries";


.form__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form__item {
  width: 100%;

  div {
    margin: 0;
  }
}


@include breakpoint_767 {
  .form__wrapper {
    flex-direction: column;
  }
}