@import "public/assets/css/variables";
@import "public/assets/css/media-queries";

.step {
  width: 100%;
}

.form__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;


  .form__item:last-child {
    width: 200px;
  }

  &.column {
    flex-direction: column;

    .form__item:last-child {
      width: 100%;
    }
  }

}


.form__item {
  width: 100%;

  div {
    margin: 0;
  }
}


@include breakpoint_767 {
  .form__wrapper {
    flex-direction: column;
  }
}