@import "public/assets/css/variables";

.wrap {
  width: 100%;
  display: flex;
  gap: 20px;

  .form_item {
    width: 50%;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    .form_item {
      width: 100%;
    }
  }

}



.mask_input {
  font-family: $common-font !important;
  height: 40px;

  &:hover {
    border: 1px solid $clr-primary;
  }

  &:focus {
    border: 1px solid $clr-primary;
    box-shadow: 0px 0px 3px $clr-primary;
  }

}

@media (max-width: 500px) {
  .input {
    min-width: 250px;
  }

  .mask_input {
    min-width: 250px;
  }
}

@media (max-width: 380px) {
  .input {
    min-width: 200px;
    max-width: 200px !important;
  }

  .mask_input {
    min-width: 200px;
    width: 100% !important;
  }
}