.header-style-3{
    position: relative; 

    .header-nav-call-section {
        float: right;
        color: $clr-white;
        display: flex;
        .detail{
            text-align: right;
            margin-right: 20px;
            span{
                display: block;
                a{
                    color: $clr-white;
                }
            }
            .title{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 400;
                display: block;
                padding-bottom: 10px;
            }
            font-size: 34px;
            font-weight: 500;
            line-height: 0.75;
            @include breakpoint_1199{
               font-size: 20px; 
            }
        }
        .media{
            img{
                opacity: 0.3;
            }
        }
        @include breakpoint_540{
            display: none;
        }
    }

    .header-search{
        a.header-search-icon{
            border: 1px solid rgba(255, 255, 255, 0.4);
            color: $clr-white;
            width: 54px;
            height: 54px;
            line-height: 54px;
            display: block;
            text-align: center;
            font-size: 24px;
            font-weight: normal;
            border-radius: 50%;
        }
    }
    .extra-nav {
        /*---Extra Nav---*/
        display: table;
        float: right;
        height: 70px;
        position: relative;
        z-index: 13;
        .extra-cell {
            display: table-cell;
            padding-left: 25px;
            vertical-align: middle;
            &:first-child{padding-left: 0px;}
            @include breakpoint_540{padding-left: 10px;}
        }
    }

    .logo-header {
        /*--logo for header---*/
        display: table;
        float: left;
        width: 190px;
        height: 70px;
        vertical-align: middle;
        padding: 0px;
        font-size: 36px;
        color:$clr-black;
        margin: 0px;
        position: relative;
        z-index: 11;
        @include breakpoint-640{
            width: 120px;  
        }
        @include breakpoint-360{
            width: 120px;  
        }
        .logo-header-inner {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            img {
                max-width: 150px;
                max-height: 150px;
            }
        }
    }

    &.site-header {
        position: absolute;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 999;
        ul,ol {margin-bottom: 0px;}
    }  

    .main-bar {
    /*--main-bar--*/
        position: relative;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: all 0.5s ease;
        background: transparent;
        .container,
        .container-fluid{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include breakpoint_991{
                display: block;
            } 
        }
        &:after,
        &:after {
            clear: both;
            content: "";
            display: table;
        }
    }
    
    .navbar-toggler {
    /*--Navbar Toggler---*/
        border: none;
        margin:24px 0px 24px 15px;
        padding: 12px 10px;
        &:focus{
            box-shadow: none !important;
        }
        @include breakpoint_991 {
            display: block;
            margin-top: 14px;
            margin-bottom: 14px;
            float: right;
            outline: none !important;
        }
        .icon-bar {
            background:$clr-white;
            height: 3px;
            border-radius: 0px;
            display: block;
            width: 22px;
        }
        .icon-bar {
            + {
                .icon-bar {
                    margin-top: 4px;
                }
            }
        }   
        @include breakpoint-991{        
            margin-top: 14px; 
            margin-bottom: 14px;      
        }
        @include breakpoint-420{        
            margin-left: 10px;        
        }
    }

    /*--is fixed---*/
    .is-fixed{
        .navbar-toggler{
            @include breakpoint_540{margin-bottom: 0px;}
        }

        &.mobile-sider-drawer-menu{ 
            .header-nav {
                .nav {
                    height: 100vh !important;
                    width: 100%;
                    >li {
                        >a {
                            padding: 12px 15px !important;
                        }
                    }
                }
            }

        }
      
    }    

    .header-nav {
        position: relative;
        padding: 0px;
        z-index: 10;
            @include breakpoint-991{
                clear: both;
                margin: 0px -15px;
                border-bottom: 1px solid #E9E9E9;
                &.navbar-collapse{
                    align-items: start;
                }
            }    
            .nav {
                display: flex;
                justify-content: flex-end; 
                flex-direction: inherit;
                @include breakpoint-991{
                    float: none;
                    margin: 0px;
                    background:$clr-white;
                    display: block;
                    width: 100%;
                    left: 0px;
                }
                li {
                    @include breakpoint-991{
                        float: none;
                    }                   
                    i {
                        font-size: 9px;
                        margin-left: 3px;
                        margin-top: -3px;
                        vertical-align: middle;
                        opacity: 0.7;
                        @include breakpoint-991{
                            float: right;                       
                        }
                    } 
                    
                }
                    
            }

            .nav>li {
                position: relative;
                margin: -10px 0px;
                padding: 10px 0px;
                font-family: $common-font;    
                ul{
                    @include breakpoint-991{
                    display: none;
                    position: static;
                    visibility: visible;
                    width: auto;
                    background: #f9f9f9;
                    }
                } 

                &>a {
                    color: $clr-white;
                    font-size: 16px;
                    padding: 15px 16px;
                    cursor: pointer;
                    margin: 0px 0px;
                    font-weight: 400;
                    display: block;
                    @include breakpoint-991{
                        padding: 12px 15px;
                        border-top: 1px solid #E9E9E9;
                        color: $clr-black;
                    }
                    &:hover{
                        background-color: transparent;
                        color:$clr-primary;
                    }
                    &:active,
                    &:focus {
                        background-color: transparent;
                    }
                            
                }
                &.active>a,
                &.current-menu-item>a {
                    @include breakpoint-991{
                        color: $clr-black;
                    }
                } 

                &>a:hover,
                &>a:active,
                &>a:focus{
                    @include breakpoint-991{
                        background-color: #f0f0f0;
                        text-decoration: none;
                    }
                } 

                &.active>a,
                &.current-menu-item>a {
                    background-color: transparent;
                    color: $clr-black;
                }               

                &:hover>a {
                    color: $clr-primary;
                }

                &:hover>.sub-menu{
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0px;
                    transition: all 0.3s ease;
                }

                .sub-menu {
                    background-color: $clr-white;
                    display: block;
                    left: 0px;
                    top: 100%;
                    list-style: none;
                    margin: 20px 0 0;
                    opacity: 0;
                    padding: 0px;
                    position: absolute;
                    visibility: hidden;
                    width: 220px;
                    z-index: 10;
                    transition: none !important;

                    @include breakpoint-991{
                        display: none;
                        position: static;
                        visibility: visible;
                        width: auto;
                        background: #f9f9f9;
                        opacity: 1;
                        margin: 0px !important;
                    }
                    li {
                        border-bottom: 1px solid #f4f4f4;
                        position: relative;
                        text-transform: none;
                        a {
                            color:$clr-black;
                            display: block;
                            font-size: 13px;
                            padding: 14px 20px;
                            padding-left: 20px;
                            font-weight: 500;
                            &:hover {
                                color: $clr-black;
                                text-decoration: none;
                            }
                        }
                        &:hover>a {
                            color: $clr-primary;
                        }
                        &:last-child {
                            border-bottom: 0px;
                        }    
                        .fa {
                            color: $clr-black;
                            display: block;
                            float: right;
                            position: absolute;
                            right: 10px;
                            top: 2px;
                        }
                    }

                    li{
                        >.sub-menu {
                            left: 220px;
                            margin: 0px 0px 0px 20px;
                            transition: all 0.1s ease;
    
                            &>li{
                                @include breakpoint-991{
                                    float: none;
                                    display: block;
                                    width: auto;
                                }
                                a {
                                    @include breakpoint-991{
                                        padding-left: 30px;
                                    }
                                }
                            } 
                        } 
                    }
                    li{
                        &:hover{
                            >.sub-menu {
                                left: 220px;
                                margin: 0px;
                                opacity: 1;
                                top: -1px;
                                visibility: visible;
                                &:before {
                                    background-color: transparent;
                                    bottom: 0px;
                                    content: '';
                                    display: block;
                                    height: 100%;
                                    left: -6px;
                                    position: absolute;
                                    top: 0px;
                                    width: 6px;
                                }                

                            }
                        }
                    } 

                }

            }

            /*-- Submenu direction---*/                         
            .submenu-direction {
                .sub-menu {
                    left: auto;
                    right: 0px;
                    li {
                        >.sub-menu {
                            left: auto;
                            right: 220px;
                            margin: 0px 20px 0px 0px;
                        }
                        &:hover {
                            >.sub-menu {
                                left: auto;
                                right: 220px;
                                margin: 0px 0px 0px 0px;
                            }
                        }
                    }
                }
            }                

        
            &.nav-animation {
                .nav {
                    >li {
                        >ul.sub-menu {
                            li {
                                >a {
                                    transition: all 0.5s ease-out;
                                    position: relative;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
            &.nav-animation {
                .nav {
                    >li {
                        &:hover {
                            >ul.sub-menu {
                                li {
                                    &:hover {
                                        >a {
                                            padding-left: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

    }


    .has-child{ 
        @include breakpoint-991{
            position: relative;  
        }
        .submenu-toogle {
            position: absolute;
            right: 4px;
            top: 4px;
            color: $clr-black;
            background-color: transparent;
            z-index: 900;
            cursor: pointer;
            padding: 10px;
            font-size: 14px;
            display: none;
            @include breakpoint-991{
                display: block;
            }
        }
        li{
            .submenu-toogle {
                opacity: 0.9;
            }
        }

        &.nav-active {
            >a {
                + {
                    .submenu-toogle.glyphicon-chevron-right {
                        &:before {
                            content: "\e114";
                        }
                    }
                }
            }
        }

        &.nav-active {
            >a {
                + {
                    .submenu-toogle.glyphicon-plus {
                        &::before {
                            content: "\2212";
                        }
                    }
                }
            }
        }

        &.nav-active {
            >a {
                + {
                    .submenu-toogle.fa.fa-angle-right {
                        &::before {
                            @include breakpoint-991{
                                content: "\f107";
                            }
                        }
                    }
                }
            }
        }          

    }         
    
    @include breakpoint_991{
        .mobile-full-width-menu{
            .header-nav{
                .nav {
                    position: inherit;
                }
            }
        }
    }
    /*====Mobile Side =======*/
    @include breakpoint_991 {
        
        &.mobile-sider-drawer-menu{
            .header-nav {
                position:fixed;
                width:250px !important;
                z-index:999;
                height:100vh !important;
                overflow-y: inherit !important;
                left:-250px;
                top:0px;
                transition: all 0.5s linear;	
                background-color:$clr-white;	
                .nav{ 
                    > li{
                        &:hover{
                            > .sub-menu{
                            animation:none !important
                            }
                        }
                    }
                }
                .nav {
                    >li {
                        .sub-menu {
                            li {
                                &:hover {
                                    >.sub-menu {
                                        animation: none !important;
                                    }
                                }
                            }
                        }
                    }
                }                
            }

            &.active {
                .header-nav {
                    left: 15px;
                }
            }

            .icon-bar{
                transition: all 0.5s linear;
                position: relative;		
            } 

            #mobile-side-drawer{min-height:41px; position: relative;}

            &.active {
                .icon-bar.icon-bar-first {
                    transform: rotate(45deg) translateX(3px) translateY(2px);
                }
            }

            &.active {
                .icon-bar.icon-bar-three {
                    transform: rotate(-45deg) translateX(3px) translateY(-2px);
                }
            }

            .icon-bar.icon-bar-two{
                position:relative;
                right:0%;
            }
            &.active {
                .icon-bar.icon-bar-two {
                    position: absolute;
                    right: 100px;
                    opacity: 0;
                }
            }

            .is-fixed {
                .header-nav {
                    .nav {
                        overflow: auto;
                        max-height: 100%;
                    }
                }
            }
            .header-fixed {
                .header-nav {
                    .nav {
                        overflow: auto;
                        max-height: 100%;
                    }
                }
            }

        }

    } 
    
    
    /*---when header is sticky---*/
    .sticky-no {
        .main-bar {
            position: static !important;
        }
    }
    /*---when header is sticky---*/
    .is-fixed {
        .main-bar {
            position: fixed;
            top: 0px;
            left: 0px;
            
        }
    }
    

    /*---header id Fixed---*/

    .is-fixed.color-fill {
        .main-bar{
            background-color: $clr-sky-blue;
        }
        .header-nav {
            .nav {
                >li {
                    margin: 0px;
                    padding: 0px;
                }
            }
        }
    }

    @include breakpoint_991  {
        .header-nav {
            .nav {
                >li {
                    margin: 0px;
                    padding: 0px;
                    >a {
                        margin: 0px;
                        padding: 10px 12px;
                    }
                }
            }
        }
        .is-fixed.color-fill {
            .header-nav {
                .nav {
                    >li {
                        margin: 0px;
                        padding: 0px;
                    }
                }
            }
        }
        
    
    }

    @include breakpoint_767  {
        .main-bar {
            padding: 0px;
        }
        .is-fixed {
            .main-bar {
                padding: 0px;
            }
        }
        .is-fixed.color-fill {
            .main-bar {
                padding: 0px;
            }
        }
        
    }        
}




//GET IN TOUCH BTN//
.ap-btn {
	position: relative;
	padding: 8px 10px;
	color: $clr-black;
	transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
	cursor: pointer;
	user-select: none;
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	border-left: 2px solid $clr-black;
	border-right: 2px solid $clr-black;
	&:before {
		content: '';
		position: absolute;
		transition: inherit;
		z-index: -1;
	}
	&:after {
		content: '';
		position: absolute;
		transition: inherit;
		z-index: -1;
	}
	&:hover {
		color: white;
		transition-delay: .6s;
		&:before {
			transition-delay: 0s;
		}
		&:after {
			background: $clr-primary;
			transition-delay: .4s;
		}
	}
}
.ap-btn.from-top {
	&:before {
		left: 0px;
		height: 0px;
		width: 100%;
		bottom: 0px;
		border: 3px solid $clr-primary;
		border-top: 0px;
		border-bottom: 0px;
	}
	&:after {
		left: 0px;
		height: 0px;
		width: 100%;
		top: 0px;
		height: 0px;
	}
	&:hover {
		&:before {
			height: 100%;
		}
		&:after {
			height: 100%;
		}
	}
}
.ap-btn.from-top.active {
	&:before {
		height: 100%;
	}
	&:after {
		height: 100%;
	}
}
